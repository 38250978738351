import { useAppSelector } from '../../../../../../../hooks/useAppSelector';
import { Roles } from '../../../../../../../types/Roles';
import * as S from '../../styles';

type Props = {
  isModePending?: boolean;
};

const HeaderLine: React.FC<Props> = ({ isModePending }) => {
  const userInfo = useAppSelector(state => state.auth);

  return (
    <S.HeaderLine>
      {!isModePending && <S.HeaderTextTime>Time</S.HeaderTextTime>}
      <S.TextBtn>Del</S.TextBtn>
      {(userInfo.role === Roles.BILLING ||
        userInfo.role === Roles.LOCATION_MANAGER ||
        userInfo.role === Roles.SUPER_ADMIN) &&
        !isModePending && <S.TextBtn>Claim</S.TextBtn>}
      <S.TextBtn>Dupl</S.TextBtn>
      <S.AppointmentsText>Appointments</S.AppointmentsText>
    </S.HeaderLine>
  );
};

export default HeaderLine;
