import { Doughnut } from 'react-chartjs-2';
import * as S from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  scales,
} from 'chart.js';
import { max } from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

type Props = {
  title?: string;
  records?: Array<any>;
  labels?: Array<String>;
  options?: any;
};

export const CHART_COLORS = {
  first: '#A1CFDC',
  second: '#E17777',
  third: '#80B5C5',
  four: '#0084B1',
  five: 'rgb(54, 162, 235)',
  six: 'rgb(153, 102, 255)',
  seven: 'rgb(201, 203, 207)',
};

const DonutChart: React.FC<Props> = ({ title, labels, records, options }) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: records,
        borderRadius: 7,
        backgroundColor: Object.values(CHART_COLORS),
        hoverOffset: 4,
      },
    ],
  };
  return (
    <S.Wrapper>
      <Doughnut options={options} data={data} />
    </S.Wrapper>
  );
};

export default DonutChart;
