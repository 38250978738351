import { useState } from 'react';
import { AddPatientsSVG } from 'assets/icons';
import { Button, CustomPagination, CustomTable, SearchInput } from 'components';
import * as S from '../../styles';
import { useBillingsGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import StaffBillingRow from './TableData/StaffBillingRow';
import { DrawerMode } from 'types/DrawerMode';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';

const headers = [
  { name: 'Billing name', key: 'Name' },
  { name: 'Phone', key: 'Phone' },
  { name: 'Email', key: 'Email' },
  { name: 'Status', key: 'Status' },
];

const BILLING_SPECIALIST_LIMIT = 20;

const StaffBilling = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const getList = useBillingsGetQuery({
    page: page,
    q: debouncedSearchValue,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  return (
    <S.TableBody>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getList.isFetching}
          setPage={setPage}
          currentPage={getList.currentData?.page}
          lastPage={getList.currentData?.lastPage}
          count={getList?.currentData?.count}
          text={'billing specialists'}
          limit={BILLING_SPECIALIST_LIMIT}
        />
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            <Button
              text="Add Billing Specialist"
              onClick={() =>
                dispatch(
                  showDrawer({
                    mode: DrawerMode.ADD_BILLING,
                    props: null,
                    show: true,
                  }),
                )
              }
            >
              <AddPatientsSVG />
            </Button>
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getList.isFetching}>
        <StaffBillingRow clickable data={getList?.currentData?.rows} headers={headers} />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffBilling;
