import { useEffect, useState } from 'react';
import { ArrowLSVG, ArrowRSVG, DownloadReportSVG } from 'assets/icons';
import { CustomTable, LinearLoader } from 'components';
import * as S from './styles';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDebounce } from 'use-debounce';
import NoPatients from './components/NoPatients';
import PatientTableRow from 'components/organismus/CustomTable/PatientTableData/PatientTableRow';
import ExportBtns from './components/ExportBtns';
import FilterPopover from './components/FilterPopover';
import SearchPatients from './components/SearchPatients';
import { useSearchAllPatientsQQuery } from 'store/api/user/profileApi';
import {
  FilterCounterValuesType,
  FilterValuesType,
  searchKeys,
  searchOperators,
} from 'helpers/FilterSearch/filterSearch';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';

type Props = {
  // selectedClinic: string;
};

const PATIENTS_LIMIT = 20;

const Patients: React.FC<Props> = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const [DOBRange, setDOBRange] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>(searchKeys[0].name);
  const [operator, setOperator] = useState<string>(searchOperators[0].key);

  const [searchKey2, setSearchKey2] = useState<string>(searchKeys[1].name);
  const [operator2, setOperator2] = useState<string>(searchOperators[0].key);
  const [searchValue2, setSearchValue2] = useState('');
  const [debouncedSearch2] = useDebounce(searchValue2, 500);
  // useEffect(() => {
  //   if (state?.isShowAllPatients) {
  //     setIsShowAllPatients(true);
  //   }
  // }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const handleSearch2 = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue2(e.target.value);
  };

  const [filterValues, setFilterValues] = useState<FilterValuesType>({
    serverIsShowAllPatients: false,
    serverStatus: [],
    serverDOBRange: '',
    serverStartDOB: '',
    serverEndDOB: '',
    serverExactDOB: '',
    serverSortLastVisit: '',
    serverStartLastVisit: '',
    serverEndLastVisit: '',
  });

  const [counterFilterValues, setCounterFilterValues] = useState<FilterCounterValuesType>(
    {
      useDOBRange: false,
      useShowAllPatients: false,
      useSortLastVisit: false,
      useSortLastVisitInRange: false,
      useStatus: false,
    },
  );

  useEffect(() => {
    setCounterFilterValues({
      useDOBRange:
        !!(filterValues.serverStartDOB ||
          filterValues.serverEndDOB ||
          filterValues.serverExactDOB),
      useShowAllPatients: filterValues.serverIsShowAllPatients,
      useSortLastVisit: !!filterValues.serverSortLastVisit,
      useSortLastVisitInRange:
        !!(filterValues.serverStartLastVisit || filterValues.serverEndLastVisit),
      useStatus:
        !(filterValues.serverStatus.length === 0 || filterValues.serverStatus.length === 4),
    });
  }, [filterValues]);

  const [isShowSecondFilter, setIsShowSecondFilter] = useState(false);

  const { data, isLoading, isFetching } = useSearchAllPatientsQQuery({
    take: 20,
    page: page,
    ...(!filterValues.serverIsShowAllPatients && {
      clinicId: selectedClinic.id,
    }),
    'status[]': filterValues.serverStatus,
    ...(filterValues.serverExactDOB && {
      exactDate: dayjs(filterValues.serverExactDOB).format('YYYY-MM-DD'),
    }),
    ...(filterValues.serverStartDOB && {
      startDOBDate: dayjs(filterValues.serverStartDOB).format('YYYY-MM-DD'),
    }),
    ...(filterValues.serverEndDOB && {
      endDOBDate: dayjs(filterValues.serverEndDOB).format('YYYY-MM-DD'),
    }),
    ...(filterValues.serverSortLastVisit && {
      lastVisitDate: filterValues.serverSortLastVisit,
    }),
    ...(filterValues.serverStartLastVisit && {
      lastVisitStart: dayjs(filterValues.serverStartLastVisit).format('YYYY-MM-DD'),
    }),
    ...(filterValues.serverEndLastVisit && {
      lastVisitEnd: dayjs(filterValues.serverEndLastVisit).format('YYYY-MM-DD'),
    }),
    ...(debouncedSearch && {
      'searchFilters[0][operator]': operator,
      'searchFilters[0][searchString]': debouncedSearch,
      'searchFilters[0][filteredTitle]': searchKey,
    }),
    ...(isShowSecondFilter &&
      debouncedSearch2 &&
      (!debouncedSearch && debouncedSearch2
        ? {
            'searchFilters[0][operator]': operator2,
            'searchFilters[0][searchString]': debouncedSearch2,
            'searchFilters[0][filteredTitle]': searchKey2,
          }
        : {
            'searchFilters[1][operator]': operator2,
            'searchFilters[1][searchString]': debouncedSearch2,
            'searchFilters[1][filteredTitle]': searchKey2,
          })),
  });

  const headers = [
    { name: 'Name', key: 'Name' },
    { name: 'Sex', key: 'Sex' },
    { name: 'DOB', key: 'DOB' },
    { name: 'Phone', key: 'Phone' },
    { name: 'Insurance', key: 'Insurance' },
    { name: 'Last Visit', key: 'LastVisitDate' },
    { name: 'Status', key: 'Status' },
    ...(debouncedSearch || debouncedSearch2
      ? [{ name: 'Search result', key: 'Search' }]
      : []),
  ];

  const [firstCount, setFirstCount] = useState(1);
  const [secondCount, setSecondCount] = useState(PATIENTS_LIMIT);

  const handleForward = () => {
    setPage(page + 1);
    setFirstCount(firstCount + PATIENTS_LIMIT);
    setSecondCount(secondCount + PATIENTS_LIMIT);
  };
  const handleBack = () => {
    setPage(page - 1);
    setFirstCount(firstCount - PATIENTS_LIMIT);
    setSecondCount(secondCount - PATIENTS_LIMIT);
  };

  useEffect(() => {
    setPage(1);
    setFirstCount(1);
    setSecondCount(PATIENTS_LIMIT);
  }, [filterValues, debouncedSearch, debouncedSearch2]);

  const handleShowCount = () => {
    if (data?.count && data.count > 0) {
      return (
        <S.PaginationText>
          Showing {firstCount}-
          {data?.count && data.count < secondCount ? data.count : secondCount} of{' '}
          {data?.count} Patients{' '}
        </S.PaginationText>
      );
    } else {
      return <S.PaginationText>Showing 0 of {data?.count} Patients </S.PaginationText>;
    }
  };

  return (
    <>
      <S.PatientsWrapper>
        <S.SubtitleBar>
          <S.SubtitleText>All Patients</S.SubtitleText>
          <S.SubtitleItems>
            <IconButton disableRipple={true} href={`/assets/importTemplates/Patient Template.csv`}>
              <DownloadReportSVG />
              <S.TextBtn>Download Template</S.TextBtn>
            </IconButton>
            <ExportBtns />
          </S.SubtitleItems>
        </S.SubtitleBar>
        <S.Content>
          <S.HeaderTable>
            <S.PaginationWrapper>
              <S.Arrows>
                <ArrowLSVG
                  onClick={handleBack}
                  className={data?.page === 1 ? 'disabled' : ''}
                />
                <ArrowRSVG
                  onClick={handleForward}
                  className={data?.page === data?.lastPage ? 'disabled' : ''}
                />
              </S.Arrows>
              {isLoading || isFetching ? <div>...</div> : handleShowCount()}
            </S.PaginationWrapper>
            <S.RightFilters>
              <FilterPopover
                counterFilterValues={counterFilterValues}
                setCounterFilterValues={setCounterFilterValues}
                DOBRange={DOBRange}
                setDOBRange={setDOBRange}
                filterValues={filterValues}
                setFilterValues={setFilterValues}
                isLoading={isLoading || isFetching}
              />
              <SearchPatients
                operator={operator}
                setOperator={setOperator}
                searchKey={searchKey}
                setSearchKey={setSearchKey}
                searchValue={searchValue}
                handleSearch={handleSearch}
                operator2={operator2}
                setOperator2={setOperator2}
                searchKey2={searchKey2}
                setSearchKey2={setSearchKey2}
                searchValue2={searchValue2}
                setSearchValue2={setSearchValue2}
                handleSearch2={handleSearch2}
                isShowSecondFilter={isShowSecondFilter}
                setIsShowSecondFilter={setIsShowSecondFilter}
              />
            </S.RightFilters>
          </S.HeaderTable>
          {isLoading || isFetching ? (
            <LinearLoader />
          ) : (
            <S.TableBody>
              {!data?.rows?.length && !isLoading && !isFetching ? (
                <NoPatients />
              ) : (
                <CustomTable headers={headers} isFetching={isLoading || isFetching}>
                  <PatientTableRow data={data?.rows} headers={headers} clickable />
                </CustomTable>
              )}
            </S.TableBody>
          )}
        </S.Content>
      </S.PatientsWrapper>
    </>
  );
};

export default Patients;
