import { CircularLoader } from '../../../../../../components';
import DonutChart from '../../../../../../components/atoms/DonutChart';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useDashTicketsGetQuery } from '../../../../../../store/api/dashboard/dashboardApi';
import * as S from '../../styles';

const TicketsCount = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const getStat = useDashTicketsGetQuery({
    startDate: '2023-01-01',
    endDate: '2024-02-01',
    clinicId: selectedClinic.id,
  });

  const labels = ['Completed Tickets', 'Active Tickets'];

  const data = [15, 4];

  const options = {
    type: 'doughnut',
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data: any, i: number) => ({
              text: `${chart.data.labels[i]} (${data})`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i,
            }));
          },
        },
      },
    },
  };

  return (
    <S.MainInfoCard>
      <S.MainContent>
        <S.SubtitleText>Tickets</S.SubtitleText>
        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching ? (
              <CircularLoader color="#0084B1" />
            ) : (
              <DonutChart
                title="Tickets"
                labels={labels}
                records={data}
                options={options}
              />
            )}
          </S.Chart>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInfoCard>
  );
};

export default TicketsCount;
