import { useEffect, useState } from 'react';
import {
  CircularLoader,
  DashboardCard,
  DatePicker,
  SelectInput,
  VerticalChart,
} from '../../../../../../components';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useVisitsTableGetQuery } from '../../../../../../store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import { ClearFiltersSVG } from '../../../../../../assets/icons';
import { getFirstAndLastDate } from '../../../../../../helpers/functions/getFirstAndLastDate';
import dayjs from 'dayjs';
import { getLastYears } from '../../../../../../helpers/functions/getLastYears';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { monthsName } from '../../../../../../constants/constants';

const AveragePatientVisits = () => {
  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const yearArr = getLastYears();

  const [filterYear, setFilterYear] = useState<number>(yearArr[0]);

  const getStat = useVisitsTableGetQuery({
    year: filterYear,
    clinicId: selectedClinic.id,
    status: 'active',
  });

  const [allLabels, setAllLabels] = useState<Array<string>>([]);

  useEffect(() => {
    if (getStat.currentData?.graph) {
      setAllLabels([]);
      getStat.currentData?.graph?.map((item: any) => {
        setAllLabels(prev => [...prev, item.month]);
      });
    }
  }, [getStat]);

  return (
    <S.MainInformation>
      <S.MainContent>
        <S.SubtitleBar>
          <S.SubtitleText>Average Patient Visit</S.SubtitleText>
          <S.SubtitleItems>
            <SelectInput
              label="Year"
              value={filterYear}
              onChange={(e: SelectChangeEvent<unknown>) =>
                setFilterYear(e.target.value as number)
              }
            >
              {yearArr.map(year => (
                <MenuItem
                  key={year}
                  value={year}
                  disabled={getStat.isFetching || getStat.isLoading}
                >
                  <S.MenuItemContent>{year}</S.MenuItemContent>
                </MenuItem>
              ))}
            </SelectInput>
          </S.SubtitleItems>
        </S.SubtitleBar>
        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching || labels.length !== 12 ? (
              <CircularLoader color="#0084B1" />
            ) : (
              <VerticalChart
                title="Patient visits"
                labels={labels}
                records={getStat.currentData?.graph}
              />
            )}
          </S.Chart>
          <S.Items>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="This month"
                number={getStat.currentData?.currentMonth}
                $isGray
              />
            </S.DashboardSmallWrapper>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="This year"
                number={getStat.currentData?.currentYear}
                $isGray
              />
            </S.DashboardSmallWrapper>
          </S.Items>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInformation>
  );
};

export default AveragePatientVisits;
