import { useFormik } from 'formik';
import * as S from './styles';
import * as yup from 'yup';
import { InitProviderType, OutProviderType } from 'types/ProvideType';
import { Button, Input, SelectInput } from 'components';
import { ChangeEvent, useEffect } from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from 'constants/statesUSA';
import {
  useCreateRefProviderMutation,
  useUpdateRefProviderMutation,
} from 'store/api/staff/staffApi';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { setMessage } from 'store/slices/message';
import ReactInputMask from 'react-input-mask';
import { emailValidator } from 'constants/constants';

type Props = {
  refProvider?: OutProviderType;
};

const Shape = yup.object().shape({
  firstName: yup.string().required('Is required'),
  lastName: yup.string().required('Is required'),
  email: emailValidator,
});

const AddReferringProvider: React.FC<Props> = ({ refProvider }) => {
  const dispatch = useAppDispatch();

  const [createRefProvider] = useCreateRefProviderMutation({});
  const [updateRefProvider] = useUpdateRefProviderMutation({});

  const formik = useFormik<InitProviderType>({
    initialValues: {
      firstName: '',
      lastName: '',
      providerId: '',
      mi: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      taxId: '',
      speciality: '',
      contact: '',
      phone: '',
      fax: '',
      email: '',
      referringProviderNpi: '',
      upin: '',
    },
    validateOnChange: false,
    validationSchema: Shape,
    onSubmit: values => {
      dispatch(showDrawer({ show: false, mode: DrawerMode.DEFAULT, props: null }));
      refProvider
        ? updateRefProvider({
            id: refProvider.id,
            ...values,
          })
            .unwrap()
            .then(res => {
              dispatch(
                setMessage({
                  message: 'Referring provider was successfully updated',
                  type: 'success',
                }),
              );
              formik.resetForm();
            })
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            })
        : createRefProvider({
            ...values,
          })
            .unwrap()
            .then(res => {
              dispatch(
                setMessage({
                  message: 'Referring provider was successfully created',
                  type: 'success',
                }),
              );
              formik.resetForm();
            })
            .catch(error => {
              dispatch(setMessage({ message: error.data.message, type: 'error' }));
            });
    },
  });

  useEffect(() => {
    if (refProvider) {
      formik.setValues({
        ...formik.values,
        firstName: refProvider.firstName || '',
        lastName: refProvider.lastName || '',
        mi: refProvider.mi || '',
        addressLine1: refProvider.addressLine1 || '',
        addressLine2: refProvider.addressLine2 || '',
        city: refProvider.city || '',
        state: refProvider.state || '',
        zip: refProvider.zip || '',
        taxId: refProvider.taxId || '',
        contact: refProvider.contact || '',
        speciality: refProvider.speciality || '',
        phone: refProvider.phone || '',
        fax: refProvider.fax || '',
        email: refProvider.email || '',
        upin: refProvider.upin || '',
        referringProviderNpi: refProvider.referringProviderNpi || '',
      });
    }
  }, [refProvider]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.ArticleWrap>
            <S.Article>
              {refProvider ? 'Edit Referring Provider' : 'Add Referring Provider'}
            </S.Article>
          </S.ArticleWrap>
          <S.PaymentInformation>
            <S.InputRow>
              <Input
                id="firstName"
                name="firstName"
                label="First name"
                isRequired
                value={formik.values.firstName}
                onChange={handleChangeInput}
                error={!!formik.errors.firstName}
                helperText={formik.errors.firstName}
              />
              <S.InputSmall>
                <Input
                  id="mi"
                  name="mi"
                  label="MI"
                  value={formik.values.mi}
                  onChange={handleChangeInput}
                  error={!!formik.errors.mi}
                  helperText={formik.errors.mi}
                />
              </S.InputSmall>
              <Input
                id="lastName"
                name="lastName"
                label="Last name"
                isRequired
                value={formik.values.lastName}
                onChange={handleChangeInput}
                error={!!formik.errors.lastName}
                helperText={formik.errors.lastName}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="addressLine1"
                name="addressLine1"
                label="Address line 1"
                value={formik.values.addressLine1}
                onChange={handleChangeInput}
                error={!!formik.errors.addressLine1}
                helperText={formik.errors.addressLine1}
              />
              <Input
                id="addressLine2"
                name="addressLine2"
                label="Address line 2"
                value={formik.values.addressLine2}
                onChange={handleChangeInput}
                error={!!formik.errors.addressLine2}
                helperText={formik.errors.addressLine2}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={handleChangeInput}
                error={!!formik.errors.city}
                helperText={formik.errors.city}
              />
              <SelectInput
                label="State"
                id="state"
                name="state"
                value={formik.values.state}
                error={!!formik.errors.state}
                helperText={formik.errors.state}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  formik.setFieldError('state', '');
                  formik.handleChange(e);
                }}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.name} value={state.abbreviation}>
                    <S.MenuItemContent>{state.name}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
              <Input
                id="zip"
                name="zip"
                label="Zip"
                value={formik.values.zip}
                onChange={handleChangeInput}
                error={!!formik.errors.zip}
                helperText={formik.errors.zip}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="taxId"
                name="taxId"
                label="TAX ID"
                value={formik.values.taxId}
                onChange={handleChangeInput}
                error={!!formik.errors.taxId}
                helperText={formik.errors.taxId}
              />
              <Input
                id="contact"
                name="contact"
                label="Contact"
                value={formik.values.contact}
                onChange={handleChangeInput}
                error={!!formik.errors.contact}
                helperText={formik.errors.contact}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="speciality"
                name="speciality"
                label="Speciality"
                value={formik.values.speciality}
                onChange={handleChangeInput}
                error={!!formik.errors.speciality}
                helperText={formik.errors.speciality}
              />
            </S.InputRow>
            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                value={formik.values.phone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  id="phone"
                  name="phone"
                  label="Phone"
                  error={!!formik.errors.phone}
                  helperText={formik.errors.phone}
                />
              </ReactInputMask>
              <ReactInputMask
                mask="+19999999999"
                value={formik.values.fax}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  id="fax"
                  name="fax"
                  label="Fax"
                  error={!!formik.errors.fax}
                  helperText={formik.errors.fax}
                />
              </ReactInputMask>
              <Input
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={handleChangeInput}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="upin"
                name="upin"
                label="UPIN"
                value={formik.values.upin}
                onChange={handleChangeInput}
                error={!!formik.errors.upin}
                helperText={formik.errors.upin}
              />
              <Input
                id="referringProviderNpi"
                name="referringProviderNpi"
                label="Individual NPI"
                value={formik.values.referringProviderNpi}
                onChange={handleChangeInput}
                error={!!formik.errors.referringProviderNpi}
                helperText={formik.errors.referringProviderNpi}
              />
            </S.InputRow>
          </S.PaymentInformation>
        </S.Content>
        <S.Footer>
          {refProvider ? (
            <S.BtnWrapper>
              <Button text="Update" type="submit" />
            </S.BtnWrapper>
          ) : (
            <S.BtnWrapper>
              <Button text="Create" type="submit" />
            </S.BtnWrapper>
          )}
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddReferringProvider;
