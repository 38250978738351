import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Input, LogoutDiv, SelectInput } from '../../../../../../components';
import * as S from './styles';
import { UserProfile } from '../../../../../../types/UserProfileTypes';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { Roles } from '../../../../../../types/Roles';
import {
  ApproveBtnSVG,
  EditBtnSVG,
  ResetApproveBtnSVG,
} from '../../../../../../assets/icons';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { setMessage } from '../../../../../../store/slices/message';
import { useUpdateOnlyProfileMutation } from '../../../../../../store/api/profile/profileApi';
import ReactInputMask from 'react-input-mask';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { StatesUSA } from '../../../../../../constants/statesUSA';
import { race } from '../../../../../../constants/constants';

type Props = {
  className?: string;
  rows: UserProfile | undefined;
};

type FormValues = {
  weight: string;
  height: string;
  gender: string;
  employerName: string;
  employerPosition: string;
  employerAddress: string;
  address: string;
  secondAddressLine: string;
  city: string;
  state: string;
  zipCode: string;
  ethnicity: string;
  religion: string;
  race: string;
  nationality: string;
};

const ProfileInfo: React.FC<Props> = ({ className, rows }) => {
  const userInfo = useAppSelector(state => state.auth);
  const [isEditable, setIsEditable] = useState(false);
  const params = useParams();
  const [updateProfile] = useUpdateOnlyProfileMutation({});

  const ProfileInfoShape = yup.object().shape({});
  const dispatch = useDispatch();

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
    initialValues,
    submitForm,
  } = useFormik<FormValues>({
    initialValues: {
      weight: '',
      height: '',
      gender: '',
      employerName: '',
      employerPosition: '',
      employerAddress: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      secondAddressLine: '',
      ethnicity: '',
      religion: '',
      race: '',
      nationality: '',
    },
    validateOnChange: false,
    validationSchema: ProfileInfoShape,
    onSubmit: async () => {
      await updateProfile({
        id: rows?.profile?.id,
        weight: Number(values.weight),
        height: values.height,
        gender: values.gender,
        employerName: values.employerName,
        employerPosition: values.employerPosition,
        employerAddress: values.employerAddress,
        address: values.address,
        city: values.city,
        state: values.state,
        ethnicity: values.ethnicity,
        religion: values.religion,
        race: values.race,
        nationality: values.nationality,
        zipCode: values.zipCode,
        secondAddressLine: values.secondAddressLine,
      })
        .unwrap()
        .then(res => {
          setIsEditable(false);
          resetForm();
          dispatch(
            setMessage({
              message: res.message,
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(
            setMessage({
              message: error.data.message,
              type: 'error',
            }),
          );
        });
    },
  });

  useEffect(() => {
    setValues({
      ...values,
      weight: String(rows?.profile?.weight) || '',
      height: String(rows?.profile?.height) || '',
      gender: rows?.profile?.gender || '',
      employerName: rows?.profile?.employerName || '',
      employerPosition: rows?.profile?.employerPosition || '',
      employerAddress: rows?.profile?.employerAddress || '',
      address: rows?.profile?.address || '',
      secondAddressLine: rows?.profile?.secondAddressLine || '',
      city: rows?.profile?.city || '',
      state: rows?.profile?.state || '',
      ethnicity: rows?.profile?.ethnicity || '',
      race: rows?.profile?.race || '',
      nationality: rows?.profile?.nationality || '',
      religion: rows?.profile?.religion || '',
      zipCode: rows?.profile?.zipCode || '',
    });
  }, [isEditable]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };
  return (
    <S.ProfileInfoWrapper>
      <form
        autoComplete="off"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <S.Content className={className}>
          {userInfo.role !== Roles.PATIENT ? (
            <S.EditBtnWrapper>
              <S.Article>Patient Demographics</S.Article>
              {isEditable ? (
                <S.ButtonsEdit>
                  <ResetApproveBtnSVG
                    onClick={() => {
                      setIsEditable(false);
                    }}
                  />
                  <ApproveBtnSVG
                    onClick={() => {
                      submitForm();
                    }}
                  />
                </S.ButtonsEdit>
              ) : (
                <EditBtnSVG onClick={() => setIsEditable(true)} />
              )}
            </S.EditBtnWrapper>
          ) : (
            <S.Article>Patient Demographics</S.Article>
          )}

          <S.BlockContent>
            <S.Items>
              <S.Item>
                <S.ItemArticle>Height</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <ReactInputMask
                      mask="9'99''"
                      value={values.height}
                      onChange={handleChangeInput}
                      disabled={false}
                    >
                      <Input
                        label=""
                        id="height"
                        name="height"
                        error={!!errors.height}
                        helperText={errors.height}
                      />
                    </ReactInputMask>
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.height || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Weight</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      type="number"
                      label=""
                      id="weight"
                      name="weight"
                      error={!!errors.weight}
                      helperText={errors.weight}
                      onChange={handleChangeInput}
                      value={values.weight}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.weight || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Gender identity</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="gender"
                      name="gender"
                      error={!!errors.gender}
                      helperText={errors.gender}
                      onChange={handleChangeInput}
                      value={values.gender}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.gender || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Ethnicity</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="ethnicity"
                      name="ethnicity"
                      error={!!errors.ethnicity}
                      helperText={errors.ethnicity}
                      onChange={handleChangeInput}
                      value={values.ethnicity}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.ethnicity || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Nationality</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="nationality"
                      name="nationality"
                      error={!!errors.nationality}
                      helperText={errors.nationality}
                      onChange={handleChangeInput}
                      value={values.nationality}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.nationality || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Race</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <SelectInput
                      id="race"
                      name="race"
                      value={values.race}
                      error={!!errors.race}
                      helperText={errors.race}
                      label=""
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setFieldError('state', '');
                        handleChange(e);
                      }}
                    >
                      {race.map(race => (
                        <MenuItem key={race.value} value={race.value}>
                          <S.MenuItemContent>{race.name}</S.MenuItemContent>
                        </MenuItem>
                      ))}
                    </SelectInput>
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.race || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Religion</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="religion"
                      name="religion"
                      error={!!errors.religion}
                      helperText={errors.religion}
                      onChange={handleChangeInput}
                      value={values.religion}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.religion || '-'}</S.ItemValue>
                )}
              </S.Item>
            </S.Items>
          </S.BlockContent>
          <S.BlockContent>
            <S.SubArticle>Job Info</S.SubArticle>
            <S.Items>
              <S.Item>
                <S.ItemArticle>Occupation</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="employerPosition"
                      name="employerPosition"
                      error={!!errors.employerPosition}
                      helperText={errors.employerPosition}
                      onChange={handleChangeInput}
                      value={values.employerPosition}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.employerPosition || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Employer Name</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="employerName"
                      name="employerName"
                      error={!!errors.employerName}
                      helperText={errors.employerName}
                      onChange={handleChangeInput}
                      value={values.employerName}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.employerName || '-'}</S.ItemValue>
                )}
              </S.Item>

              <S.Item>
                <S.ItemArticle>Employer Address</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="employerAddress"
                      name="employerAddress"
                      error={!!errors.employerAddress}
                      helperText={errors.employerAddress}
                      onChange={handleChangeInput}
                      value={values.employerAddress}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.employerAddress || '-'}</S.ItemValue>
                )}
              </S.Item>
            </S.Items>
          </S.BlockContent>
          <S.BlockContent>
            <S.Article>Patient Contact Information</S.Article>
            <S.SubArticle>Address</S.SubArticle>
            <S.Items>
              <S.Item>
                <S.ItemArticle>Address line 1</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="address"
                      name="address"
                      error={!!errors.address}
                      helperText={errors.address}
                      onChange={handleChangeInput}
                      value={values.address}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.address || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>Address line 2</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="secondAddressLine"
                      name="secondAddressLine"
                      error={!!errors.secondAddressLine}
                      helperText={errors.secondAddressLine}
                      onChange={handleChangeInput}
                      value={values.secondAddressLine}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.secondAddressLine || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>City</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="city"
                      name="city"
                      error={!!errors.city}
                      helperText={errors.city}
                      onChange={handleChangeInput}
                      value={values.city}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.city || '-'}</S.ItemValue>
                )}
              </S.Item>
              <S.Item>
                <S.ItemArticle>State</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <SelectInput
                      label=""
                      id="state"
                      name="state"
                      value={values.state}
                      error={!!errors.state}
                      helperText={errors.state}
                      onChange={(e: SelectChangeEvent<unknown>) => {
                        setFieldError('state', '');
                        handleChange(e);
                      }}
                    >
                      {StatesUSA.map(state => (
                        <MenuItem key={state.name} value={state.abbreviation}>
                          <S.MenuItemContent>{state.name}</S.MenuItemContent>
                        </MenuItem>
                      ))}
                    </SelectInput>
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.state || '-'}</S.ItemValue>
                )}
              </S.Item>

              <S.Item>
                <S.ItemArticle>Zip</S.ItemArticle>
                {isEditable ? (
                  <S.ItemValue>
                    <Input
                      label=""
                      id="zipCode"
                      name="zipCode"
                      error={!!errors.zipCode}
                      helperText={errors.zipCode}
                      onChange={handleChangeInput}
                      value={values.zipCode}
                    />
                  </S.ItemValue>
                ) : (
                  <S.ItemValue>{rows?.profile?.zipCode || '-'}</S.ItemValue>
                )}
              </S.Item>
            </S.Items>
          </S.BlockContent>
        </S.Content>
        {!params.patientId && <LogoutDiv className={className} />}
      </form>
    </S.ProfileInfoWrapper>
  );
};

export default ProfileInfo;
