import { AddClinicIconSVG, FilterSVG } from 'assets/icons';
import {
  Button,
  CheckBox,
  CustomPagination,
  CustomTable,
  Dialog,
  SearchInput,
  SelectInput,
} from 'components';
import * as S from './styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTemplatesGetQuery } from 'store/api/templates/templateApi';
import CreateTemplateDialog from './components/CreateTemplateDialog';
import TemplateRow from './components/TableData/TemplateRow';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { templateEnum } from 'constants/constants';
import { TemplateNameType } from 'types/TemplateType';
import { getCorrectTemplateName } from './configTemplate';
import { Roles } from 'types/Roles';

const TEMPLATES_LIMIT = 20;

const Templates = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(state => state.auth);
  const headers = [
    { name: 'Template name', key: 'TemplateName' },
    // { name: "Created by", key: "CreatedBy" },
    { name: 'Date', key: 'Date' },
    { name: 'Note', key: 'Note' },
    ...(userInfo.role === Roles.SUPER_ADMIN
      ? [
          {
            name: 'Settings',
            key: 'Settings',
          },
        ]
      : []),
    { name: '', key: 'Btns' },
  ];

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [isShowCreateDialog, setIsShowCreateDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearch] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  const [filterStatus, setFilterStatus] = useState<TemplateNameType[]>([
    TemplateNameType.CLIENT,
    TemplateNameType.DIGITAL,
    TemplateNameType.PDF,
  ]);

  const listResult = useTemplatesGetQuery({
    q: debouncedSearch,
    clinicId: selectedClinic.id,
    page: page,
    'type[]': filterStatus,
  });

  const handleShowCreateDialog = () => {
    setIsShowCreateDialog(!isShowCreateDialog);
  };

  const handleChangeSelect = (e: SelectChangeEvent<any>) => {
    setFilterStatus(e.target.value);
    setPage(1);
  };

  return (
    <S.Wrapper>
      <Dialog open={isShowCreateDialog} onClose={handleShowCreateDialog}>
        <CreateTemplateDialog onClose={handleShowCreateDialog} />
      </Dialog>
      <S.SubtitleBar>
        <S.SubtitleText>Templates</S.SubtitleText>
        <S.SubtitleItems>
          {userInfo.role === Roles.SUPER_ADMIN && (
            <S.ButtonWrapper>
              <Button text="Add Template" onClick={handleShowCreateDialog}>
                <AddClinicIconSVG />
              </Button>
            </S.ButtonWrapper>
          )}
        </S.SubtitleItems>
      </S.SubtitleBar>
      <S.Content>
        <S.HeaderTable>
          <CustomPagination
            isFetching={listResult.isFetching}
            setPage={setPage}
            currentPage={listResult.currentData?.page}
            lastPage={listResult.currentData?.lastPage}
            count={listResult?.currentData?.count}
            text={'documents'}
            limit={TEMPLATES_LIMIT}
          />
          <S.RightFilters>
            <S.SelectInputWrapper>
              <SelectInput
                label=""
                multiple
                background="#fff"
                // disabled={mainStatus.isLoading}
                value={filterStatus}
                renderValue={(selected: any) => (
                  <S.SelectInputOutput>
                    <FilterSVG />
                    <S.SelectInputOutputText>
                      {selected.length === 0 || selected.length === templateEnum.length
                        ? 'All'
                        : selected.map(
                            (item: TemplateNameType) =>
                              getCorrectTemplateName(item) + ', ',
                          )}
                    </S.SelectInputOutputText>
                  </S.SelectInputOutput>
                )}
                onChange={handleChangeSelect}
              >
                {templateEnum.map(template => (
                  <MenuItem key={template.value} value={template.value}>
                    <S.MenuItemContent>
                      <CheckBox checked={filterStatus.indexOf(template.value) > -1} />
                      {template.name}
                    </S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
            </S.SelectInputWrapper>
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
          </S.RightFilters>
        </S.HeaderTable>
        <S.TableBody>
          <CustomTable headers={headers} isFetching={listResult.isFetching}>
            <TemplateRow data={listResult?.currentData?.rows} headers={headers} />
          </CustomTable>
        </S.TableBody>
      </S.Content>
    </S.Wrapper>
  );
};

export default Templates;
