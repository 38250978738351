import React, { useEffect, useState } from 'react';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { useGetAllDoctorsQuery } from 'store/api/staff/staffApi';
import { MenuItem, RadioGroup } from '@mui/material';
import { TooltipInput, RadioButton, TooltipSelectInput } from 'components';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import { FormikProps } from 'formik';
import { ProviderInfoFormType } from 'types/AppointmentNewTypes';

import * as S from '../../styles';

type Props = {
  providerFormik: FormikProps<Partial<ProviderInfoFormType>>;
};

export const RenderingCard = ({ providerFormik }: Props) => {
  const clinics = useClinicsGetQuery({});
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const getAll = useGetAllDoctorsQuery({
    q: debouncedSearchValue,
    page: 1,
  });

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const onChangeClinic = (clinicName: string) => {
    const currentClinic = clinics.currentData?.rows?.find(i => i.name === clinicName);

    if (currentClinic) {
      void providerFormik.setValues({
        ...providerFormik.values,
        renderingName: currentClinic?.name ?? '',
        renderingNpi: currentClinic?.npi ?? '',
      });
    }
  };

  const setInitValue = () => {
    setSelectedValue(null);
    void providerFormik.setValues({
      ...providerFormik.values,
      renderingName: '',
      renderingNpi: '',
    });
  };

  useEffect(() => {
    if (selectedValue) {
      void providerFormik.setValues({
        ...providerFormik.values,
        renderingName: selectedValue.user?.profile
          ? selectedValue.user?.profile?.firstName +
            ' ' +
            selectedValue.user?.profile?.lastName
          : '',
        renderingNpi:
          providerFormik.values?.renderingName ===
          selectedValue.user?.profile?.firstName +
            ' ' +
            selectedValue.user?.profile?.lastName
            ? providerFormik.values?.renderingNpi
            : (selectedValue.user?.credentials?.npi ?? ''),
      });
    }
  }, [selectedValue]);

  return (
    <S.Card>
      <S.EmptyDiv>
        <S.Caption>31. RENDERING PROVIDER</S.Caption>
        <RadioGroup
          value={providerFormik.values.isFhysicanRendering}
          onChange={e => {
            setInitValue();
            providerFormik.setFieldValue('isFhysicanRendering', e.target.value);
          }}
        >
          <S.Label>
            <S.LabelArticle></S.LabelArticle>
          </S.Label>
          <S.RadioItem>
            <S.RadioButtons>
              <RadioButton label="Facility" value={'isFacilityRendering'} />
              <RadioButton label="Physician" value={'isFhysicanRendering'} />
            </S.RadioButtons>
          </S.RadioItem>
        </RadioGroup>
        <S.InputWrapper className="all">
          {providerFormik.values.isFhysicanRendering === 'isFhysicanRendering' ? (
            <>
              <S.InputAutoCompleteWrapper>
                <AutoCompleteInput
                  data={getAll?.currentData?.rows || []}
                  mainLabel={'Name'}
                  loading={!!getAll.isLoading}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  defaultValue={providerFormik.values?.renderingName ?? undefined}
                  error={!!providerFormik.errors.renderingName}
                  helperText={providerFormik.errors.renderingName}
                />
              </S.InputAutoCompleteWrapper>
            </>
          ) : (
            <S.EmptyDiv className={'smallGap'}>
              <TooltipSelectInput
                label="Facility Name"
                value={providerFormik.values.renderingName}
                onChange={e => onChangeClinic(e.target.value as string)}
                error={!!providerFormik.errors.renderingName}
                tooltipHelperText={providerFormik.errors.renderingName}
              >
                {clinics.currentData?.rows.map(clinic => (
                  <MenuItem key={clinic.id} value={clinic?.name}>
                    <S.MenuItemContent>{clinic.name}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </TooltipSelectInput>
            </S.EmptyDiv>
          )}
        </S.InputWrapper>

        <S.Gap>
          <S.Caption>RENDERING PROVIDER ID</S.Caption>
        </S.Gap>
        <S.InputWrapper className="all">
          <TooltipInput
            label="NPI"
            {...providerFormik.getFieldProps('renderingNpi')}
            error={!!providerFormik.errors.renderingNpi}
            tooltipHelperText={providerFormik.errors.renderingNpi}
          />
        </S.InputWrapper>
      </S.EmptyDiv>
    </S.Card>
  );
};
