import DonutChart from 'components/atoms/DonutChart';
import * as S from '../../styles';

const Masseur = () => {
  const labels = ['Masseur 1', 'Masseur 2', 'Masseur 3', 'Masseur 4'];

  const data = [80, 40, 20, 20];

  const options = {
    type: 'doughnut',
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data: any, i: number) => ({
              text: `${chart.data.labels[i]} (${data} H)`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i,
            }));
          },
        },
      },
    },
  };

  return (
    <S.MainInfoCard>
      <S.MainContent>
        <S.SubtitleText>Masseur</S.SubtitleText>
        <S.ChartWrapper>
          <S.Chart>
            <DonutChart
              title="Tickets"
              labels={labels}
              records={data}
              options={options}
            />
          </S.Chart>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInfoCard>
  );
};

export default Masseur;
