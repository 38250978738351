import { useState } from 'react';
import { ClearFiltersSVG } from '../../../../../../assets/icons';
import { DashboardCard, DatePicker } from '../../../../../../components';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import { useVisitsGetQuery } from '../../../../../../store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import dayjs, { Dayjs } from 'dayjs';
import { useDebounce } from 'use-debounce';
import { getMonth } from '../../../../../../helpers/CalendarHelpers/getMonth';
import { getFirstAndLastDate } from '../../../../../../helpers/functions/getFirstAndLastDate';

const StatisticHeaderBar = () => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const [firstDate, setFirstDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).firstDate,
  );
  const [secondDate, setSecondDate] = useState<Dayjs | null | string>(
    getFirstAndLastDate(dayjs()).lastDate,
  );

  const [debouncedFirstDate] = useDebounce(firstDate, 500);
  const [debounceSecondDate] = useDebounce(secondDate, 500);

  const visits = useVisitsGetQuery({
    clinicId: selectedClinic.id,
    startDate: debouncedFirstDate,
    endDate: debounceSecondDate,
  });

  return (
    <S.ContainerWrapper>
      <S.DashboardFilterWrapper>
        <S.Header>
          <S.DatePickerWrapper>
            <DatePicker
              disabled={visits.isFetching || visits.isLoading}
              label="Date from"
              shouldDisableDate={date => {
                if (secondDate && date > secondDate) {
                  return true;
                } else {
                  return false;
                }
              }}
              value={firstDate}
              onChange={value => {
                setFirstDate(dayjs(value));
              }}
              id="birthDate"
              name="birthDate"
            />
          </S.DatePickerWrapper>
          {'-'}
          <S.DatePickerWrapper>
            <DatePicker
              disabled={visits.isFetching || visits.isLoading}
              label="Date to"
              shouldDisableDate={date => {
                if (firstDate && date < firstDate) {
                  return true;
                } else {
                  return false;
                }
              }}
              value={secondDate}
              onChange={value => {
                setSecondDate(dayjs(value));
              }}
              id="birthDate"
              name="birthDate"
            />
          </S.DatePickerWrapper>
          <S.ClearFilter
            onClick={() => {
              setFirstDate(getFirstAndLastDate(dayjs()).firstDate);
              setSecondDate(getFirstAndLastDate(dayjs()).lastDate);
            }}
          >
            <ClearFiltersSVG />
          </S.ClearFilter>
        </S.Header>
      </S.DashboardFilterWrapper>
      <S.DashboardItems>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={visits.isFetching || visits.isLoading ? true : false}
            article="Total registered Visit Reasons"
            number={visits.currentData?.totalVisits}
          />
        </S.DashboardCardWrapper>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={visits.isFetching || visits.isLoading ? true : false}
            article="Active Visit Reasons"
            number={visits.currentData?.activeVisits}
          />
        </S.DashboardCardWrapper>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={visits.isFetching || visits.isLoading ? true : false}
            article="Completed Visit Reasons"
            number={visits.currentData?.completedVisits}
          />
        </S.DashboardCardWrapper>
        <S.DashboardCardWrapper>
          <DashboardCard
            $isLoading={visits.isFetching || visits.isLoading ? true : false}
            article="Canceled Visit Reasons"
            number={visits.currentData?.canceledVisits}
          />
        </S.DashboardCardWrapper>
      </S.DashboardItems>
    </S.ContainerWrapper>
  );
};

export default StatisticHeaderBar;
