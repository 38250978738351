import { useState } from 'react';
import { Button, CustomPagination, CustomTable, Dialog, SearchInput } from 'components';
import { useAppSelector } from 'hooks/useAppSelector';
import { useStaffGetQuery } from 'store/api/clinics/clinicApi';
import { TabsEnum } from 'types/enums/TabsEnum';
import * as S from '../../styles';
import StaffRecepRow from './TableData/StaffRecepRow';
import { AddPatientsSVG } from 'assets/icons';
import { useDebounce } from 'use-debounce';
import { useParams } from 'react-router-dom';
import { Roles } from 'types/Roles';
import AssignClinicDialog from 'pages/SuperAdmin/components/ProfileStaff/components/AssignTo/components/AssignClinicDialog';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';

const headers = [
  { name: 'Receptionist name', key: 'ReceptionistName' },
  { name: 'Clinic name', key: 'ClinicName' },
  { name: 'Phone', key: 'Phone' },
  { name: 'Email', key: 'Email' },
  { name: 'Status', key: 'Status' },
];

type Props = {
  fromClinic?: boolean;
};

const RECEPTIONISTS_LIMIT = 20;

const StaffReceptionists = ({ fromClinic }: Props) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);

  const params = useParams();

  const getReceptionistsList = useStaffGetQuery({
    position: TabsEnum.RECEPTIONIST,
    id: params.clinicId || selectedClinic.id,
    page: page,
    take: 20,
    q: debouncedSearchValue,
  });

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  return (
    <S.TableBody>
      <Dialog open={openDialog} onClose={() => setOpenDialog(!openDialog)}>
        <AssignClinicDialog
          onClose={() => setOpenDialog(!openDialog)}
          role={Roles.RECEPTIONIST}
          clinicId={params.clinicId}
        />
      </Dialog>
      <S.HeaderTable>
        <CustomPagination
          isFetching={getReceptionistsList.isFetching}
          setPage={setPage}
          currentPage={getReceptionistsList.currentData?.page}
          lastPage={getReceptionistsList.currentData?.lastPage}
          count={getReceptionistsList?.currentData?.count}
          text={'receptionists'}
          limit={RECEPTIONISTS_LIMIT}
        />
        <S.SubtitleItems>
          <S.SearchWrapper>
            <SearchInput value={searchValue} onChange={handleSearch} />
          </S.SearchWrapper>
          <S.ButtonWrapper>
            {fromClinic ? (
              <Button
                text={'Assign Receptionist'}
                onClick={() => {
                  setOpenDialog(!openDialog);
                }}
              >
                <AddPatientsSVG />
              </Button>
            ) : (
              <Button
                text="Add Receptionists"
                onClick={() =>
                  dispatch(
                    showDrawer({
                      mode: DrawerMode.ADD_RECEPTIONIST,
                      props: Roles.RECEPTIONIST,
                      show: true,
                    }),
                  )
                }
              >
                <AddPatientsSVG />
              </Button>
            )}
          </S.ButtonWrapper>
        </S.SubtitleItems>
      </S.HeaderTable>
      <CustomTable headers={headers} isFetching={getReceptionistsList.isFetching}>
        <StaffRecepRow
          clickable
          data={getReceptionistsList?.currentData?.rows}
          headers={headers}
        />
      </CustomTable>
    </S.TableBody>
  );
};

export default StaffReceptionists;
