import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDashCashGetQuery } from 'store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import { CircularLoader, DashboardCard, VerticalChart } from 'components';

const DashCash = () => {
  const labels = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const getStat = useDashCashGetQuery({
    clinicId: selectedClinic.id,
    year: '2024',
  });

  const [allLabels, setAllLabels] = useState<Array<string>>([]);

  useEffect(() => {
    if (getStat.currentData?.graph) {
      setAllLabels([]);
      getStat.currentData?.graph?.map((item: any) => {
        setAllLabels(prev => [...prev, item.month]);
      });
    }
  }, [getStat]);

  const options = {
    responsive: true,

    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Cash Out',
        backgroundColor: '#E17777',
        data: getStat.currentData?.graph?.map((item: any) => item.totalCashOut),
        borderRadius: 7,
      },
      {
        label: 'Cash In',
        backgroundColor: '#0084B1',
        borderRadius: 10,
        data: getStat.currentData?.graph?.map((item: any) => item.totalCashIn),
      },
    ],
  };

  return (
    <S.MainInformation>
      <S.MainContent>
        <S.SubtitleText>Cash</S.SubtitleText>
        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching ? (
              <CircularLoader color="#0084B1" />
            ) : (
              <VerticalChart stackedData={data} propsOptions={options} />
            )}
          </S.Chart>
          <S.Items>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Cash in (USD)"
                number={getStat.currentData?.totalCashIn}
                $isGray
              />
            </S.DashboardSmallWrapper>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Cash out (USD)"
                number={getStat.currentData?.totalCashOut}
                $isGray
              />
            </S.DashboardSmallWrapper>
          </S.Items>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInformation>
  );
};

export default DashCash;
