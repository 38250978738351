import { Appointments } from '../../../types/AppointmentsTypes';
import { Note } from '../../../types/NoteTypes';
import { api } from '../api';

type Response<T> = {
  count: number;
  page: number;
  lastPage: number;
  rows: Array<T>;
  pinnedCount: number;
};

export const dashboardApi = api.injectEndpoints({
  endpoints: builder => ({
    visitsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/visit`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    visitsTableGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/visit/table`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashTicketsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/ticket`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashVisitReasonsGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/visit-reasons`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
    dashCashGet: builder.query<any, any>({
      query: params => ({
        url: `/dashboard/cash`,
        params: { ...params },
      }),
      providesTags: ['Dashboard'],
    }),
  }),
});

export const {
  useVisitsGetQuery,
  useVisitsTableGetQuery,
  useDashTicketsGetQuery,
  useDashVisitReasonsGetQuery,
  useDashCashGetQuery,
} = dashboardApi;
