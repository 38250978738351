import { IconButton, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { SelectInput, Input } from 'components';
import * as S from './styles';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { FormikProps } from 'formik';
import { paymentsMethods } from 'constants/constants';
import ReactInputMask from 'react-input-mask';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useCouponCheckMutation } from 'store/api/coupons/couponsApi';
import { UserProfile } from 'types/UserProfileTypes';
import { AppointmentsNew } from 'types/AppointmentNewTypes';
import { AppointmentStatus } from 'types/StatusTypes';
import { CloseBtnSVG } from 'assets/icons';

type Props = {
  formik: FormikProps<AppointmentFormValues>;
  userProfile?: UserProfile;
  selectedAppointment?: AppointmentsNew;
};

const AppointmentPayment: React.FC<Props> = ({
  formik,
  userProfile,
  selectedAppointment,
}) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldError(e.target.id, '');
    formik.handleChange(e);
  };

  const [errorText, setErrorText] = useState<string>('');
  const [couponText] = useDebounce(formik.values.coupon, 500);

  const [checkCoupon, checkCouponStatus] = useCouponCheckMutation({});

  useEffect(() => {
    // if (selectedEvent) {
    // } else
    if (
      selectedAppointment?.patient?.id &&
      !selectedAppointment.coupon &&
      couponText &&
      couponText.length === 8 &&
      !(couponText as string).includes('_')
    ) {
      checkCoupon({
        patientId: selectedAppointment?.patient?.id,
        value: couponText,
        clinicId: formik.values.clinicId,
      })
        .unwrap()
        .then(res => formik.setFieldValue('discountText', res?.payload?.benefit))
        .catch(err => {
          formik.setFieldValue('discountText', '');
          setErrorText(err.data.message);
        });
    } else {
      formik.setFieldValue('discountText', '');
      setErrorText('');
    }
  }, [couponText]);

  useEffect(() => {
    if (userProfile) {
      formik.setValues({
        ...formik.values,
        paymentMethod: userProfile.selectedPaymentMethod || '',
      });
    }
  }, [userProfile]);

  return (
    <S.AppointmentInformation>
      <S.SelectContent>
        <S.SubArticle>Payment method</S.SubArticle>
        <S.SelectInputWrap>
          <SelectInput
            label=""
            id="paymentMethod"
            name="paymentMethod"
            error={!!formik.errors.paymentMethod}
            helperText={formik.errors.paymentMethod}
            value={formik.values.paymentMethod}
            onChange={(e: SelectChangeEvent<unknown>) => {
              formik.setFieldError('paymentMethod', '');
              formik.handleChange(e);
            }}
            disabled={
              selectedAppointment?.status === AppointmentStatus.COMPLETED ||
              selectedAppointment?.status === AppointmentStatus.CANCELED
            }
            endAdornment={
              !!formik.values.paymentMethod && (
                <InputAdornment sx={{ marginRight: '12px' }} position="end">
                  <IconButton
                    onClick={() => {
                      formik.setFieldValue('paymentMethod', '');
                    }}
                  >
                    <CloseBtnSVG />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {paymentsMethods.map(pay => (
              <MenuItem key={pay.name} value={pay.value}>
                <S.MenuItemContent>{pay.name}</S.MenuItemContent>
              </MenuItem>
            ))}
          </SelectInput>
        </S.SelectInputWrap>
      </S.SelectContent>
      {selectedAppointment && (
        <>
          {selectedAppointment.status !== AppointmentStatus.PENDING && (
            <>
              <S.SelectContent>
                <S.SubArticle>Cash sum</S.SubArticle>
                <S.SelectInputWrap>
                  <Input
                    isSum={'USD'}
                    label=""
                    id={`cashSum`}
                    name={`cashSum`}
                    error={!!formik.errors.cashSum}
                    helperText={formik.errors.cashSum}
                    value={formik.values.cashSum}
                    onChange={handleChangeInput}
                    disabled={
                      selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                      selectedAppointment?.status === AppointmentStatus.CANCELED
                    }
                  />
                </S.SelectInputWrap>
              </S.SelectContent>
              <S.SelectContent>
                <S.SubArticle>Cash note</S.SubArticle>
                <S.SelectInputWrap>
                  <Input
                    label=""
                    multiline
                    rows={2}
                    id={`cashNote`}
                    name={`cashNote`}
                    error={!!formik.errors.cashNote}
                    helperText={formik.errors.cashNote}
                    value={formik.values.cashNote}
                    onChange={handleChangeInput}
                    disabled={
                      selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                      selectedAppointment?.status === AppointmentStatus.CANCELED
                    }
                  />
                </S.SelectInputWrap>
              </S.SelectContent>
            </>
          )}

          <S.SelectContent>
            <S.SubArticle>Coupon number</S.SubArticle>
            <S.SelectInputWrap>
              <ReactInputMask
                mask="********"
                value={formik.values.coupon?.toUpperCase() ?? ''}
                onChange={handleChangeInput}
                disabled={
                  !!selectedAppointment.coupon?.value ||
                  selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                  selectedAppointment?.status === AppointmentStatus.CANCELED
                }
              >
                <Input
                  label=""
                  id={`coupon`}
                  name={`coupon`}
                  error={!!formik.errors.coupon}
                  helperText={formik.errors.coupon}
                />
              </ReactInputMask>
              <S.HelperText>
                {selectedAppointment.coupon
                  ? selectedAppointment.coupon.benefit
                  : formik.values.discountText || errorText}
              </S.HelperText>
            </S.SelectInputWrap>
          </S.SelectContent>
        </>
      )}
    </S.AppointmentInformation>
  );
};

export default AppointmentPayment;
