import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { Button, CustomPagination, CustomTable, Drawer } from 'components';
import * as S from 'pages/SuperAdmin/components/Insurance/styles';
import { Roles } from 'types/Roles';
import { AddRectangleSVG } from 'assets/icons';
import { headers } from './config';
import { ServiceTableRow } from './components';
import { useGetAllServicesQuery } from 'store/api/services/servicesApi';
import { AddService } from 'components/organismus/Drawers/AddService';

const SERVICES_LIMIT = 20;

export const Services = () => {
  const userInfo = useAppSelector(state => state.auth);
  const [showEditServiceDrawer, setShowEditServiceDrawer] = useState<string | null>(null);
  const [showNewServiceDrawer, setShowNewServiceDrawer] = useState(false);

  const [page, setPage] = useState(1);

  const listResult = useGetAllServicesQuery({
    take: SERVICES_LIMIT,
    asc: 'DESC',
    page,
    /*clinicIds:
      userInfo.role === Roles.LOCATION_MANAGER
        ? userInfo.clinics?.map(i => i.id)
        : undefined,*/
  });

  useEffect(() => {
    listResult.refetch();
  }, [page]);

  return (
    <S.Wrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Insurance</S.SubtitleText>
      </S.SubtitleBar>

      <S.Content>
        <S.HeaderTable>
          <CustomPagination
            isFetching={listResult.isFetching}
            setPage={setPage}
            currentPage={listResult.currentData?.page}
            lastPage={listResult.currentData?.lastPage}
            count={listResult?.currentData?.count}
            text={'services'}
            limit={SERVICES_LIMIT}
          />
          <S.SubtitleItems>
            {[Roles.SUPER_ADMIN, Roles.LOCATION_MANAGER].includes(
              userInfo.role as Roles,
            ) && (
              <S.ButtonWrapper>
                <Button text="Add Service" onClick={() => setShowNewServiceDrawer(true)}>
                  <AddRectangleSVG />
                </Button>
              </S.ButtonWrapper>
            )}
          </S.SubtitleItems>
        </S.HeaderTable>
        <S.TableBody>
          <CustomTable headers={headers} isFetching={listResult.isFetching}>
            <ServiceTableRow
              data={listResult?.currentData?.rows}
              headers={headers}
              setShowEditServiceDrawer={setShowEditServiceDrawer}
            />
          </CustomTable>
        </S.TableBody>
      </S.Content>

      <Drawer
        open={!!showEditServiceDrawer}
        onClose={() => setShowEditServiceDrawer(null)}
      >
        {!!showEditServiceDrawer && (
          <AddService
            currentService={listResult?.currentData?.rows?.find(
              i => i.id === showEditServiceDrawer,
            )}
            services={
              listResult?.currentData?.rows?.filter(
                i => i.id !== showEditServiceDrawer,
              ) ?? []
            }
            onClose={() => setShowEditServiceDrawer(null)}
          />
        )}
      </Drawer>
      <Drawer open={showNewServiceDrawer} onClose={() => setShowNewServiceDrawer(false)}>
        {showNewServiceDrawer && (
          <AddService
            services={listResult?.currentData?.rows ?? []}
            onClose={() => setShowNewServiceDrawer(false)}
          />
        )}
      </Drawer>
    </S.Wrapper>
  );
};
