import { FormHelperText, SelectProps } from '@mui/material';
import * as S from './styles';

type Props = {
  placeholder?: string;
  children?: React.ReactNode;
  width?: string;
  height?: string;
  isPassword?: boolean;
  label: string;
  background?: string;
  isRequired?: boolean;
  rows?: number;
  multiline?: boolean;
  className?: string;
  multiple?: boolean;
  helperText?: any;
  error?: boolean;
  isCell?: boolean;
} & SelectProps<unknown>;

const SelectInput: React.FC<Props> = ({
  label,
  background,
  isPassword,
  error,
  width,
  height,
  isCell,
  isRequired,
  rows,
  multiline,
  children,
  multiple = false,
  placeholder,
  helperText,
  ...props
}) => {
  return (
    <S.SelectInputWrapper>
      {(label || isRequired) && (
        <S.Label htmlFor="input">
          {label} {isRequired && <S.RequiredSpan>*</S.RequiredSpan>}
        </S.Label>
      )}
      <S.SelectInput
        variant={isCell ? 'standard' : undefined}
        className={isCell ? 'isCell' : ''}
        displayEmpty={true}
        error={error}
        multiple={multiple}
        style={{
          backgroundColor: background,
          borderColor: error ? 'red' : '',
        }}
        MenuProps={{
          sx: {
            '& .MuiList-root': {
              maxHeight: '400px',
            },
          },
        }}
        {...props}
      >
        {children}
      </S.SelectInput>
      {helperText && (
        <S.FormHelperText>
          <FormHelperText>{helperText}</FormHelperText>
        </S.FormHelperText>
      )}
    </S.SelectInputWrapper>
  );
};

export default SelectInput;
