import { useNavigate } from 'react-router-dom';
import { AvatarSVG, EnvelopeSVG, StrokeSVG } from 'assets/icons';
import * as S from './styles';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import NotificationPopover from '../../organismus/NotificationPopover';
import NotificationRow from '../NotificationRow';
import avatar from 'assets/images/avatarmock.jpg';
import { useAppSelector } from 'hooks/useAppSelector';
import {
  useNotificationsAllGetQuery,
  useReadAllNotificationsMutation,
} from 'store/api/notifications/notificationsApi';
import { SelectInput } from '../..';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useLastSelectedClinicMutation } from 'store/api/user/profileApi';
import { setSelectedClinic } from 'store/slices/selectedClinic';
import { useClinicsGetQuery } from 'store/api/clinics/clinicApi';
import StartedAppointmentButton from '../StartedAppointmentButton';
import { Roles } from 'types/Roles';
import { setIsDirty } from 'store/slices/isDirtyFormSlice';
import { useLazyGetServicesByClinicQuery } from 'store/api/services/servicesApi';

type Props = {
  isNotify?: boolean;
};

const HorizontalHeader: React.FC<Props> = ({ isNotify }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const userInfo = useAppSelector(state => state.auth);
  const notificationCount = useAppSelector(state => state.chats.notificationsCount);

  const [getServiceList] = useLazyGetServicesByClinicQuery();

  const [page, setPage] = useState(1);

  const getNotification = useNotificationsAllGetQuery({
    userId: userInfo.id,
    take: 5,
    page: page,
  });

  const [readAll] = useReadAllNotificationsMutation();

  const handleAnchor = (e: React.MouseEvent<HTMLElement>) => {
    setPage(1);
    getNotification.refetch();
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const [observerTarget, setObserverTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentElement = observerTarget;
    const currentObserver = new IntersectionObserver(entries => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPage(page + 1);
      }
      return;
    });

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [observerTarget]);

  const clinics = useClinicsGetQuery({});

  const dispatch = useAppDispatch();
  const { isDirty } = useAppSelector(state => state.isDirty);

  const [changeSelectedClinic, status] = useLastSelectedClinicMutation();

  const [clinic, setClinics] = useState('');

  useEffect(() => {
    if (!userInfo.selectedClinicId) {
      userInfo?.clinics && setClinics(userInfo?.clinics[0]?.id);
      userInfo?.clinics && dispatch(setSelectedClinic({ id: userInfo?.clinics[0]?.id }));
    } else {
      userInfo && setClinics(userInfo.selectedClinicId);
      userInfo && dispatch(setSelectedClinic({ id: userInfo.selectedClinicId }));
    }
  }, [userInfo]);

  useEffect(() => {
    if (clinic) {
      getServiceList({
        clinicId: clinic,
        take: 100,
        asc: 'DESC',
        page: 1,
      });
    }
  }, [clinic]);

  const startedAppointment = useAppSelector(state => state.chats.startedAppointmentCount);

  return (
    <>
      <S.HorizontalHeaderRoot>
        {startedAppointment?.startedAppointments &&
          startedAppointment.startedAppointments.length > 0 && (
            <StartedAppointmentButton
              appointmentsList={startedAppointment?.startedAppointments}
            />
          )}
        {userInfo.role !== Roles.PATIENT && (
          <S.SelectInputWrapper>
            <SelectInput
              label=""
              id="clinic"
              background="#fff"
              name="clinic"
              value={clinic}
              onChange={(e: SelectChangeEvent<unknown>) => {
                setClinics(e.target.value as string);
                dispatch(setSelectedClinic({ id: e.target.value as string }));
                changeSelectedClinic({
                  clinicId: e.target.value as string,
                });
              }}
            >
              {userInfo.clinics && userInfo.clinics.length > 0
                ? userInfo.clinics?.map(item => {
                    return (
                      <MenuItem
                        value={item?.id}
                        key={item?.id}
                        disabled={status.isLoading}
                      >
                        <S.MenuItemContent>{item?.name}</S.MenuItemContent>
                      </MenuItem>
                    );
                  })
                : clinics.currentData?.rows?.map(item => {
                    return (
                      <MenuItem
                        value={item?.id}
                        key={item?.id}
                        disabled={status.isLoading}
                      >
                        <S.MenuItemContent>{item?.name}</S.MenuItemContent>
                      </MenuItem>
                    );
                  })}
            </SelectInput>
          </S.SelectInputWrapper>
        )}
        <S.HeaderContent>
          <S.SVGItems>
            <S.EnveloperWrapper
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                handleAnchor(e);
              }}
            >
              <EnvelopeSVG />
              {notificationCount?.notificationsCount ? <S.Dot /> : ''}
            </S.EnveloperWrapper>
            <NotificationPopover
              article="Notifications"
              showSeeMore={true}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => {
                setAnchorEl(null);
                readAll({});
              }}
            >
              <S.ContentWrapper>
                {getNotification.currentData?.rows?.map((item: any, index: number) => {
                  const isLast =
                    index === getNotification.currentData?.rows?.length - 1 &&
                    getNotification.currentData &&
                    page < getNotification.currentData.lastPage;
                  return (
                    <S.ScrollRef
                      key={item.id + index}
                      ref={isLast ? setObserverTarget : undefined}
                    >
                      <NotificationRow
                        key={item.id}
                        src={avatar}
                        isApproved={false}
                        visitName={item.title}
                        notificationItem={item}
                      />
                    </S.ScrollRef>
                  );
                })}
              </S.ContentWrapper>
            </NotificationPopover>
            <StrokeSVG />
          </S.SVGItems>
          <S.UserInfo
            onClick={() => {
              isDirty
                ? dispatch(
                    setIsDirty({
                      isShowDialog: true,
                      isDirty: true,
                      action: () => navigate('/profile'),
                    }),
                  )
                : navigate('/profile');
            }}
          >
            <S.Username>
              {userInfo?.firstName} {userInfo?.lastName}
            </S.Username>
            {userInfo?.avatar?.url ? (
              <img src={userInfo?.avatar?.url} alt={userInfo?.avatar?.name} />
            ) : (
              <AvatarSVG />
            )}
          </S.UserInfo>
        </S.HeaderContent>
      </S.HorizontalHeaderRoot>
    </>
  );
};

export default HorizontalHeader;
