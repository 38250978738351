import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import { Button, DatePicker, Dialog, Input, SelectInput } from 'components';
import { Relation } from 'types/enums/AppointmentEnum';
import * as S from './styles';
import { InsuranceInUserProfileType } from 'types/InsuranceType';
import { useFormik } from 'formik';
import * as yup from 'yup';
import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import {
  useInsuranceWithGroupsUpdateMutation,
  useLazyInsuranceListWithGroupsGetQuery,
} from 'store/api/insuranceRelative/insuranceRelativeApi';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setMessage } from 'store/slices/message';
import { CloseBtnSVG, SearchIconSVG } from 'assets/icons';
import PayerSearchDialog from 'components/molecules/PayerSearchDialog';
import { PayerRow } from 'types/Payer';
import {
  emptyGroupNumber,
  NewGroupType,
  yupGroupSchema,
} from 'pages/SuperAdmin/components/InsuranceProfile/config';
import { GroupNumberCard } from 'pages/SuperAdmin/components/InsuranceProfile/GroupNumberCard';
import CheckBoxWithLabel from 'components/atoms/CheckboxWithLabel';
import { useDebounce } from 'use-debounce';

type Props = {
  onClose: () => void;
  row: InsuranceInUserProfileType | null;
  userId?: string;
  setIsDirtyForm: (isDirtyForm: boolean) => void;
};

const Schema = yup.object().shape({
  effectiveStartDate: yup.string().required('Is required'),
  effectiveStopDate: yup.string().nullable(),
  primaryInsuranceName: yup.string().trim().required('Is required'),
  subsriberName: yup.string().trim().required('Is required'),
  relationship: yup.string().required('Is required'),
  insuranceId: yup.string().trim().required('Is required'),
  groupName: yup.string().trim().optional(),
  payerId: yup.string().trim().required('Is required'),

  groups: yup.array().of(yupGroupSchema).nullable(),
});

export type InsuranceFormValues = {
  effectiveStartDate: Dayjs | string;
  effectiveStopDate: Dayjs | string;
  primaryInsuranceName: string;
  companyPhone: string;
  subsriberName: string;
  payerId: string;
  relationship: string;
  ssn: string;
  insuranceId: string;
  groupNumber: string;
  groupName: string;

  groups: NewGroupType[];
};

const AddInsuranceDialog = ({ onClose, row, userId, setIsDirtyForm }: Props) => {
  const dispatch = useAppDispatch();
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PayerRow | null>(null);

  const [updateInsurance, updateStatus] = useInsuranceWithGroupsUpdateMutation({});
  const [getInsuranceList, status] = useLazyInsuranceListWithGroupsGetQuery();

  const formik = useFormik<InsuranceFormValues>({
    initialValues: {
      effectiveStartDate: '',
      effectiveStopDate: '',
      primaryInsuranceName: '',
      companyPhone: '',
      payerId: '',
      subsriberName: '',
      relationship: '',
      ssn: '',
      insuranceId: '',
      groupNumber: '',
      groupName: '',

      groups: [],
    },
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: () => {
      setIsDirtyForm(false);

      const insuranceData = {
        id: row?.id ?? undefined,
        effectiveStartDate: formik.values.effectiveStartDate
          ? dayjs(formik.values.effectiveStartDate).format('YYYY-MM-DD')
          : null,
        effectiveStopDate: formik.values.effectiveStopDate
          ? dayjs(formik.values.effectiveStopDate).format('YYYY-MM-DD')
          : null,
        primaryInsuranceCompany: formik.values.primaryInsuranceName?.trim(),
        companyPhone: formik.values.companyPhone,
        subscriberName: formik.values.subsriberName?.trim(),
        relation: formik.values.relationship,
        ssn: formik.values.ssn?.trim(),
        insuranceId: formik.values.insuranceId?.trim(),
        groupName: formik.values.groupName?.trim(),
        profileId: userId,
      };
      const insuranceTemplateData = {
        payerId: formik.values.payerId?.trim(),
        insuranceName: formik.values.primaryInsuranceName?.trim(),
        insuranceTemplateId: row ? row?.insuranceTemplate?.id : undefined,
        insuranceGroupNumbers: formik.values.groups?.map(i => {
          return {
            id: row && i.id ? i.id : undefined,
            groupNumber: i.groupNumber,
            parentInsuranceName: i.parentInsuranceName,
            inNetMet: i.inNetMet || 0,
            inNetTotal: i.inNetTotal || 0,
            inOutMet: i.inOutMet || 0,
            inOutTotal: i.inOutTotal || 0,
            pocketLimitInFrom: i.pocketLimitInFrom || 0,
            pocketLimitInTo: i.pocketLimitInTo || 0,
            pocketLimitOutFrom: i.pocketLimitOutFrom || 0,
            pocketLimitOutTo: i.pocketLimitOutTo || 0,
            insuranceOnPolicyIn: i.insuranceOnPolicyIn || 0,
            insuranceOnPolicyOut: i.insuranceOnPolicyOut || 0,
            copayIn: i.copayIn || 0,
            copayOut: i.copayOut || 0,
            isReferallRequired: i.isReferallRequired ?? undefined,
            isAuthorizationRequired: i.isAuthorizationRequired ?? undefined,
            note: i.note || undefined,
            coveredServices: i.coveredServices?.length
              ? i.coveredServices.map(o => {
                  return {
                    id: row ? o.id : undefined,
                    typeServiceId: o?.typeServiceId,
                    isUnlimited: o.isUnlimited,
                    type: o.type,
                    visitAuthorizet: o.isUnlimited ? 0 : o.visitAuthorizet,
                    visitUsed: row ? (o.isUnlimited ? 0 : o.visitUsed) : undefined,
                  };
                })
              : [],
          };
        }),
      };

      updateInsurance({ insuranceData, insuranceTemplateData })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Insurance was successfully created',
              type: 'success',
            }),
          );
        })
        .catch(error => {
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
      onClose();
    },
  });

  useEffect(() => {
    if (row) {
      formik.setValues({
        ...formik.values,
        effectiveStartDate: dayjs(row.effectiveStartDate) || '',
        effectiveStopDate: row.effectiveStopDate ? dayjs(row.effectiveStopDate) : '',
        primaryInsuranceName: row?.primaryInsuranceCompany || '',
        companyPhone: row?.companyPhone || '',
        subsriberName: row?.subscriberName || '',
        relationship: row?.relation || '',
        ssn: row?.ssn || '',
        payerId: row?.insuranceTemplate?.payerId ?? '',
        insuranceId: row?.insuranceId || '',
        groupNumber:
          row?.insuranceTemplate?.insuranceGroupNumbers?.at(0)?.groupNumber ?? '',
        groupName: row?.groupName || '',

        groups: row?.insuranceTemplate?.insuranceGroupNumbers?.map(i => ({
          oldGroupNumber: undefined,
          id: i.id ?? undefined,
          groupNumber: i.groupNumber,
          parentInsuranceName: i.parentInsuranceName,
          inNetMet: i.inNetMet ?? 0,
          inNetTotal: i.inNetTotal ?? 0,
          inOutMet: i.inOutMet ?? 0,
          inOutTotal: i.inOutTotal ?? 0,
          pocketLimitInFrom: i.pocketLimitInFrom ?? 0,
          pocketLimitInTo: i.pocketLimitInTo ?? 0,
          pocketLimitOutFrom: i.pocketLimitOutFrom ?? 0,
          pocketLimitOutTo: i.pocketLimitIOutTo ?? 0,
          insuranceOnPolicyIn: i.insuranceOnPolicyIn ?? 0,
          insuranceOnPolicyOut: i.insuranceOnPolicyOut ?? 0,
          copayIn: i.copayIn ?? 0,
          copayOut: i.copayOut ?? 0,
          isReferallRequired: i.isReferallRequired,
          isAuthorizationRequired: i.isAuthorizationRequired,
          note: i.note,

          coveredServices: i.coveredServices?.length
            ? i.coveredServices?.map(covered => {
                return {
                  id: covered.id,
                  isUnlimited: covered?.isUnlimited,
                  typeServiceId: covered?.typeServiceId,
                  type: covered?.type,
                  visitAuthorizet: covered?.visitAuthorizet,
                  visitUsed: covered?.visitUsed,
                };
              })
            : [],
        })),
      });
    }
  }, [row]);

  useEffect(() => {
    if (selectedRow) {
      formik.setValues({
        ...formik.values,
        payerId: selectedRow.payerId,
        primaryInsuranceName: selectedRow.payerName,
      });
    }
  }, [selectedRow]);

  const [debouncedSearch] = useDebounce(formik.values.primaryInsuranceName, 500);

  useEffect(() => {
    if (debouncedSearch) {
      getInsuranceList({
        searchKey: 'insuranceName',
        q: formik.values.primaryInsuranceName,
      }); //TODO: remove this useEffect after connect to production
    }
  }, [debouncedSearch]);

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <S.Content>
        <Dialog
          open={isOpenSearchDialog}
          onClose={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
        >
          <PayerSearchDialog
            onClose={() => setIsOpenSearchDialog(!isOpenSearchDialog)}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
          />
        </Dialog>
        <S.Article>{row ? 'Edit Insurance ' : 'Add Insurance'}</S.Article>

        <S.MainContent>
          <S.InputItems>
            <S.InputRow>
              <S.InputWrapper>
                <DatePicker
                  isRequired
                  label="Effective Start Date"
                  id="effectiveStartDate"
                  name="effectiveStartDate"
                  error={!!formik.errors.effectiveStartDate}
                  helperText={formik.errors.effectiveStartDate}
                  value={formik.values.effectiveStartDate}
                  onChange={value => {
                    setIsDirtyForm(true);
                    formik.setFieldError('effectiveStartDate', '');
                    formik.setFieldValue('effectiveStartDate', value);
                  }}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <DatePicker
                  isRequired
                  label="Effective Stop Date"
                  id="effectiveStopDate"
                  name="effectiveStopDate"
                  error={!!formik.errors.effectiveStopDate}
                  helperText={formik.errors.effectiveStopDate}
                  value={formik.values.effectiveStopDate}
                  onChange={value => {
                    setIsDirtyForm(true);
                    formik.setFieldError('effectiveStopDate', '');
                    formik.setFieldValue('effectiveStopDate', value);
                  }}
                />
              </S.InputWrapper>
            </S.InputRow>
            <S.InputRow>
              <S.InputWrapper>
                <ReactInputMask
                  id="payerId"
                  name="payerId"
                  mask={'*****'}
                  value={formik.values.payerId}
                  maskPlaceholder={'_'}
                  disabled={!!selectedRow}
                  onChange={e => {
                    setIsDirtyForm(true);
                    formik.setFieldError('payerId', '');
                    formik.handleChange(e);
                  }}
                >
                  <Input
                    label="PayerID"
                    isRequired
                    id="payerID"
                    name="payerID"
                    error={!!formik.errors.payerId}
                    helperText={formik.errors.payerId}
                  />
                </ReactInputMask>
              </S.InputWrapper>
              <S.InputWrapper className="large">
                <Input
                  disabled={!!selectedRow}
                  isRequired
                  label="Insurance Company"
                  id="primaryInsuranceName"
                  name="primaryInsuranceName"
                  error={!!formik.errors.primaryInsuranceName}
                  helperText={formik.errors.primaryInsuranceName}
                  value={formik.values.primaryInsuranceName}
                  onChange={e => {
                    setIsDirtyForm(true);
                    formik.setFieldError('primaryInsuranceName', '');
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
              <S.SearchBtn onClick={() => setIsOpenSearchDialog(true)}>
                <SearchIconSVG />
                Search
              </S.SearchBtn>
            </S.InputRow>
            <S.InputRow>
              <S.InputWrapper>
                <ReactInputMask
                  mask="+19999999999"
                  value={formik.values.companyPhone}
                  onChange={e => {
                    setIsDirtyForm(true);
                    formik.setFieldError('companyPhone', '');
                    formik.handleChange(e);
                  }}
                >
                  <Input
                    label="Company phone"
                    id="companyPhone"
                    name="companyPhone"
                    error={!!formik.errors.companyPhone}
                    helperText={formik.errors.companyPhone}
                  />
                </ReactInputMask>
              </S.InputWrapper>
              <S.InputWrapper>
                <Input
                  isRequired
                  label="Insured/ Subscriber Name"
                  id="subsriberName"
                  name="subsriberName"
                  error={!!formik.errors.subsriberName}
                  helperText={formik.errors.subsriberName}
                  value={formik.values.subsriberName}
                  onChange={e => {
                    setIsDirtyForm(true);
                    formik.setFieldError('subsriberName', '');
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
              <S.InputWrapper>
                <SelectInput
                  isRequired
                  label="Relationship to Patient"
                  id="relationship"
                  name="relationship"
                  error={!!formik.errors.relationship}
                  helperText={formik.errors.relationship}
                  value={formik.values.relationship}
                  onChange={e => {
                    setIsDirtyForm(true);
                    formik.setFieldError('relationship', '');
                    formik.handleChange(e);
                  }}
                >
                  <MenuItem value={Relation.SELF}>
                    <S.MenuItemContent>Self</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.CHILD}>
                    <S.MenuItemContent>Child</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.SPOUSE}>
                    <S.MenuItemContent>Spouse</S.MenuItemContent>
                  </MenuItem>
                  <MenuItem value={Relation.OTHER}>
                    <S.MenuItemContent>Other</S.MenuItemContent>
                  </MenuItem>
                </SelectInput>
              </S.InputWrapper>
              <S.InputWrapper>
                <Input
                  label="Insured SSN"
                  id="ssn"
                  name="ssn"
                  error={!!formik.errors.ssn}
                  helperText={formik.errors.ssn}
                  value={formik.values.ssn}
                  onChange={e => {
                    setIsDirtyForm(true);
                    formik.setFieldError('ssn', '');
                    formik.handleChange(e);
                  }}
                />
              </S.InputWrapper>
            </S.InputRow>
            <S.InputRow>
              <Input
                isRequired
                label="ID"
                id="insuranceId"
                name="insuranceId"
                error={!!formik.errors.insuranceId}
                helperText={formik.errors.insuranceId}
                value={formik.values.insuranceId}
                onChange={e => {
                  setIsDirtyForm(true);
                  formik.setFieldError('insuranceId', '');
                  formik.handleChange(e);
                }}
              />

              <SelectInput
                label="Group #"
                id="groupNumber"
                name="groupNumber"
                error={!!formik.errors.groupNumber}
                helperText={formik.errors.groupNumber}
                value={formik.values.groupNumber}
                onChange={e => {
                  setIsDirtyForm(true);
                  const currentGroupNumber = status.data?.rows
                    ?.at(0)
                    ?.insuranceGroupNumbers?.find(i => e.target.value === i.groupNumber);

                  formik.setValues({
                    ...formik.values,
                    groupNumber: e.target.value as string,
                    groups: currentGroupNumber
                      ? [
                          {
                            ...currentGroupNumber,
                            coveredServices: currentGroupNumber.coveredServices?.map(
                              ({ id, ...item }) => item,
                            ),
                            id:
                              row?.insuranceTemplate?.insuranceGroupNumbers?.at(0)?.id ??
                              undefined,
                          },
                        ]
                      : [],
                  });
                }}
                endAdornment={
                  !!formik.values.groupNumber && (
                    <InputAdornment sx={{ marginRight: '12px' }} position="end">
                      <IconButton
                        onClick={() => {
                          setIsDirtyForm(true);
                          formik.setFieldValue(`groupNumber`, '');
                          formik.setFieldValue(`groups`, []);
                        }}
                      >
                        <CloseBtnSVG />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {!!formik.values.primaryInsuranceName &&
                  status.data?.rows?.at(0)?.insuranceGroupNumbers?.map(group => {
                    return (
                      <MenuItem value={group?.groupNumber} key={group?.id}>
                        <S.MenuItemContent>{group.groupNumber}</S.MenuItemContent>
                      </MenuItem>
                    );
                  })}
              </SelectInput>

              <Input
                label="Group name"
                id="groupName"
                name="groupName"
                value={formik.values.groupName}
                error={!!formik.errors.groupName}
                helperText={formik.errors.groupName}
                onChange={e => {
                  setIsDirtyForm(true);
                  formik.setFieldError('groupName', '');
                  formik.handleChange(e);
                }}
              />
            </S.InputRow>

            {formik.values?.groups?.map((group, index) => (
              <React.Fragment key={index}>
                <CheckBoxWithLabel
                  isArticle
                  label="Do you want to fill the information according group number?"
                  checked={!group.oldGroupNumber}
                  onChange={e => {
                    setIsDirtyForm(true);
                    const currentGroupNumber = status.data?.rows
                      ?.at(0)
                      ?.insuranceGroupNumbers?.find(
                        i => i.groupNumber === group.oldGroupNumber,
                      );
                    formik.setFieldValue(
                      `groups[${index}]`,
                      e.target.checked
                        ? {
                            ...currentGroupNumber,
                            oldGroupNumber: undefined,
                          }
                        : {
                            ...emptyGroupNumber(formik.values.primaryInsuranceName),
                            oldGroupNumber: group.groupNumber,
                          },
                    );
                  }}
                />
                <GroupNumberCard
                  formik={formik}
                  index={index}
                  fromInsurancePage={!row}
                  setIsDirtyForm={setIsDirtyForm}
                />
              </React.Fragment>
            ))}
          </S.InputItems>
        </S.MainContent>
        <S.ButtonWrapper>
          <Button text={row ? 'Edit' : 'Add'} type="submit" />
        </S.ButtonWrapper>
      </S.Content>
    </form>
  );
};

export default AddInsuranceDialog;
