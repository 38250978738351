import { ClaimValues, DiagnosisCodes } from 'types/AppointmentNewTypes';
import { GroupHealthPlanEnum } from 'types/ClaimTypes';
import * as yup from 'yup';
import { Relation } from 'types/enums/AppointmentEnum';
import { Profile } from 'types/ProfileTypes';
import { REQUIRED_FIELD, TOO_LONG } from 'constants/constants';
import { UserProviderType } from 'types/UserProfileTypes';
import { ucFirst } from 'helpers/functions/toUpperCase';

export const patientClaimForm = (data?: ClaimValues) => {
  return {
    defaultToGroupHealthPlan:
      data?.defaultToGroupHealthPlan ?? GroupHealthPlanEnum.GROUPHEALTHPLAN,
    patientName: data?.patientName || '',
    patientBirthDate: data?.patientBirthDate || '',
    patientGender: data?.patientGender ? ucFirst(data.patientGender) : '',
    patientAddress: data?.patientAddress || '',
    patientCity: data?.patientCity || '',
    patientState: data?.patientState || '',
    patientZip: data?.patientZip || '',
    patientPhone: data?.patientPhone || '',
    isPatientEmployment: data?.isPatientEmployment ?? false,
    isPatientAutoAccident: data?.isPatientAutoAccident ?? false,
    state: data?.state || '',
    isPatientOtherAccident: data?.isPatientOtherAccident ?? false,
  };
};

export const patientFromUser = (data?: Profile) => {
  return {
    defaultToGroupHealthPlan: GroupHealthPlanEnum.GROUPHEALTHPLAN,
    patientName: data?.firstName + ' ' + data?.lastName || '',
    patientBirthDate: data?.dateOfBirth || '',
    patientGender: data?.sex ? ucFirst(data.sex) : '',
    patientAddress: data?.address || '',
    patientCity: data?.city || '',
    patientState: data?.state || '',
    patientZip: data?.zipCode || '',
    patientPhone: data?.cellPhone || '',
    isPatientEmployment: false,
    isPatientAutoAccident: false,
    state: '',
    isPatientOtherAccident: false,
  };
};

export const patientClaimSchema = yup.object().shape({
  defaultToGroupHealthPlan: yup.string().required(REQUIRED_FIELD),
  patientName: yup.string().trim().required(REQUIRED_FIELD),
  patientBirthDate: yup.string().required(REQUIRED_FIELD),
  patientGender: yup.string().required(REQUIRED_FIELD),
  patientAddress: yup.string().trim().required(REQUIRED_FIELD),
  patientCity: yup.string().trim().required(REQUIRED_FIELD),
  patientState: yup.string().required(REQUIRED_FIELD),
  patientZip: yup.string().trim().required(REQUIRED_FIELD),
  patientPhone: yup.string().nullable(),
  isPatientEmployment: yup.boolean(),
  isPatientAutoAccident: yup.boolean(),
  state: yup.string().nullable(),
  isPatientOtherAccident: yup.boolean(),
});

export const insuranceClaimForm = (data?: ClaimValues) => {
  return {
    insuredIdNumber: data?.insuredIdNumber ?? '',
    patientRelationshipToInsured:
      (data?.patientRelationshipToInsured as Relation) ?? Relation.OTHER,
    insuredName: data?.insuredName ?? '',
    insuredBirthDate: data?.insuredBirthDate ?? '',
    insuredGender: data?.insuredGender ? ucFirst(data.insuredGender) : '',

    insuredAddress: data?.insuredAddress ?? '',
    insuredCity: data?.insuredCity ?? '',
    insuredState: data?.insuredState ?? '',
    insuredZip: data?.insuredZip ?? '',
    insuredPhone: data?.insuredPhone ?? '',

    insurancePlanNameOrProgramName: data?.insurancePlanNameOrProgramName ?? '',
    insuredPayerId: data?.insuredPayerId ?? '',
    otherClaimIdType: data?.otherClaimIdType ?? '',
    otherClaimId: data?.otherClaimId ?? '',
    insuredPolicyGroupFECANumber: data?.insuredPolicyGroupFECANumber ?? '',
    isAnotherHealthBenefitPlan: data?.isAnotherHealthBenefitPlan ?? false,
    reservedForNUCC: data?.reservedForNUCC ?? '',
    otherInsuredName: data?.otherInsuredName ?? '',

    patientSignature: data?.patientSignature ?? 'Signature on File',
    patientSignatureDate: data?.patientSignatureDate ?? '',
    insuredSignature: data?.patientSignature ?? 'Signature on File',
  };
};

export const insuranceClaimSchema = yup.object().shape({
  insuredIdNumber: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  patientRelationshipToInsured: yup.string().required(REQUIRED_FIELD),
  insuredName: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  insuredBirthDate: yup.string().required(REQUIRED_FIELD),
  insuredGender: yup.string().required(REQUIRED_FIELD),

  insuredAddress: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  insuredCity: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  insuredState: yup.string().required(REQUIRED_FIELD),
  insuredZip: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  insuredPhone: yup.string().nullable(),

  insurancePlanNameOrProgramName: yup.string().trim().required(REQUIRED_FIELD),
  otherClaimIdType: yup.string().max(2, TOO_LONG).nullable(),
  otherClaimId: yup.string().max(28, TOO_LONG).trim().nullable(),
  insuredPolicyGroupFECANumber: yup
    .string()
    .max(29, TOO_LONG)
    .trim()
    .required(REQUIRED_FIELD),
  isAnotherHealthBenefitPlan: yup.boolean(),
  reservedForNUCC: yup.string().trim().nullable(),
  otherInsuredName: yup.string().trim().nullable(),

  patientSignature: yup.string().max(71, TOO_LONG).trim().required(REQUIRED_FIELD),
  patientSignatureDate: yup.string().required(REQUIRED_FIELD),
  insuredSignature: yup.string().max(71, TOO_LONG).required(REQUIRED_FIELD),
});

export const injuryClaimForm = (
  data?: ClaimValues,
  provider?: UserProviderType | null,
) => {
  const getDiagnosisCodes = () => {
    if (data?.diagnosisCodes?.length) {
      const emptyDiagnosisCodes: DiagnosisCodes[] = Array(
        4 - (data?.diagnosisCodes?.length % 4),
      ).fill({
        code: '',
        type: data?.typeICD,
      });

      return [...data?.diagnosisCodes, ...emptyDiagnosisCodes];
    }
  };

  return {
    dateOfIllness: data?.dateOfIllness ?? '',
    dateOfIllnessQual: data?.dateOfIllnessQual ?? '',
    otherDate: data?.otherDate ?? '',
    otherDateQual: data?.otherDateQual ?? '',
    unableToWorkStartDate: data?.unableToWorkStartDate ?? '',
    unableToWorkEndDate: data?.unableToWorkEndDate ?? '',
    nameOfReferringProviderType: data?.nameOfReferringProviderType ?? '',
    nameOfReferringProvider: data?.nameOfReferringProvider
      ? data?.nameOfReferringProvider
      : provider
        ? provider?.firstName + ' ' + provider?.lastName
        : '',
    referringProviderNpi: data?.referringProviderNpi
      ? data?.referringProviderNpi
      : (provider?.referringProviderNpi ?? ''),
    hospitalizationStartDate: data?.hospitalizationStartDate ?? '',
    hospitalizationEndDate: data?.hospitalizationEndDate ?? '',
    isOutsideLab: data?.isOutsideLab ?? false,
    labCharges: data?.labCharges,
    additionalClaimInfo: data?.additionalClaimInfo ?? '',
    typeICD: 'ICD-10', //data?.typeICD || 'ICD-10'
    diagnosisCodes: getDiagnosisCodes(),

    resubmissionCode: data?.resubmissionCode ?? '',
    originalRef: data?.originalRef ?? '',
    priorAuthorizationNumber: data?.priorAuthorizationNumber ?? '',
  };
};

export const injuryClaimSchema = yup.object().shape({
  dateOfIllness: yup.string().nullable(),
  dateOfIllnessQual: yup.string().nullable(),
  otherDate: yup.string().nullable(),
  otherDateQual: yup.string().nullable(),
  unableToWorkStartDate: yup.string().nullable(),
  unableToWorkEndDate: yup.string().nullable(),
  nameOfReferringProviderType: yup.string().nullable(),
  nameOfReferringProvider: yup.string().max(24, TOO_LONG).trim().nullable(),
  referringProviderNpi: yup.string().max(29, TOO_LONG).trim().nullable(),
  hospitalizationStartDate: yup.string().nullable(),
  hospitalizationEndDate: yup.string().nullable(),
  isOutsideLab: yup.boolean(),
  labCharges: yup.number().nullable(),
  additionalClaimInfo: yup.string().max(72, TOO_LONG).trim().nullable(),
  typeICD: yup.string().nullable(),

  resubmissionCode: yup.string().trim().nullable(),
  originalRef: yup.string().max(18, TOO_LONG).trim().nullable(),
  priorAuthorizationNumber: yup.string().max(29, TOO_LONG).trim().nullable(),
});

export const paymentClaimForm = (data?: ClaimValues) => {
  return {
    services: data?.services ?? [],
    federalTaxId: data?.federalTaxId ?? '',
    isFederalSSN: data?.isFederalSSN ?? false,
    isFederalEIN: data?.isFederalSSN ? 'SSN' : 'EIN',
    patientAccountNumber: data?.patientAccountNumber ?? '',
    isAcceptAssignment: data?.isAcceptAssignment ?? true,
    totalCharge: data?.totalCharge,
    totalPaid: data?.totalPaid,
  };
};

export const yupServiceSchema = yup.object().shape({
  dateFrom: yup.string().required(REQUIRED_FIELD),
  dateTo: yup.string().required(REQUIRED_FIELD),
  placeOfService: yup.string().trim().required(REQUIRED_FIELD),
  emg: yup.string().max(1, TOO_LONG).trim().nullable(),
  cpt: yup.string().max(6, TOO_LONG).trim().required(REQUIRED_FIELD),
  modifier: yup.array().of(yup.string().max(6, TOO_LONG).trim().nullable()),
  pointer: yup.string().trim().nullable(),
  charges: yup.number().min(0, REQUIRED_FIELD).required(REQUIRED_FIELD),
  units: yup.string().trim().required(REQUIRED_FIELD),
  epsdt: yup.string().trim().nullable(),
  idQual: yup.string().trim().nullable(),
  //renderingProviderId: yup.string().trim().required(REQUIRED_FIELD),
});

export const paymentClaimSchema = yup.object().shape({
  services: yup.array().of(yupServiceSchema).nullable(),
  federalTaxId: yup.string().trim().required(REQUIRED_FIELD),
  patientAccountNumber: yup.string().trim().required(REQUIRED_FIELD),
  isAcceptAssignment: yup.boolean(),
  totalCharge: yup.number().min(0, REQUIRED_FIELD).required(REQUIRED_FIELD),
  totalPaid: yup.number().min(0, REQUIRED_FIELD).nullable(),
});

export const providerClaimForm = (data?: ClaimValues) => {
  return {
    isFacilityRendering: data?.isFacilityRendering ?? false,
    isFhysicanRendering: data?.isFacilityRendering
      ? 'isFacilityRendering'
      : 'isFhysicanRendering',
    renderingName: data?.renderingName ?? '',
    renderingNpi: data?.renderingNpi ?? '',

    serviceFacilityName: data?.serviceFacilityName ?? '',
    serviceStreet: data?.serviceStreet ?? '',
    serviceOffice: data?.serviceOffice ?? '',
    serviceCity: data?.serviceCity ?? '',
    serviceState: data?.serviceState ?? '',
    serviceZip: data?.serviceZip ?? '',
    serviceNpi: data?.serviceNpi ?? '',

    isFacilityBilling: data?.isFhysicanBilling
      ? 'isFhysicanBilling'
      : 'isFacilityBilling',
    isFhysicanBilling: data?.isFhysicanBilling ?? false,
    billingFacilityName: data?.billingFacilityName ?? '',
    billingStreet: data?.billingStreet ?? '',
    billingOffice: data?.billingOffice ?? '',
    billingCity: data?.billingCity ?? '',
    billingState: data?.billingState ?? '',
    billingZip: data?.billingZip ?? '',
    billingEin: data?.billingEin ?? '',
    billingNpi: data?.billingNpi ?? '',
  };
};

export const providerClaimSchema = yup.object().shape({
  renderingName: yup.string().max(71, TOO_LONG).trim().required(REQUIRED_FIELD),
  renderingNpi: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),

  serviceFacilityName: yup.string().max(71, TOO_LONG).trim().required(REQUIRED_FIELD),
  serviceStreet: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  serviceOffice: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  serviceCity: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  serviceState: yup.string().trim().required(REQUIRED_FIELD),
  serviceZip: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
  serviceNpi: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),

  //isFacilityBilling: yup.string().trim(),
  billingFacilityName: yup.string().max(71, TOO_LONG).trim().required(REQUIRED_FIELD),
  billingStreet: yup.string().when('isFacilityBilling', ([isFacilityBilling]) => {
    return isFacilityBilling === 'isFacilityBilling'
      ? yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD)
      : yup.string().nullable();
  }),
  billingOffice: yup.string().when('isFacilityBilling', ([isFacilityBilling]) => {
    return isFacilityBilling === 'isFacilityBilling'
      ? yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD)
      : yup.string().nullable();
  }),
  billingCity: yup.string().when('isFacilityBilling', ([isFacilityBilling]) => {
    return isFacilityBilling === 'isFacilityBilling'
      ? yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD)
      : yup.string().nullable();
  }),
  billingState: yup.string().when('isFacilityBilling', ([isFacilityBilling]) => {
    return isFacilityBilling === 'isFacilityBilling'
      ? yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD)
      : yup.string().nullable();
  }),
  billingZip: yup.string().when('isFacilityBilling', ([isFacilityBilling]) => {
    return isFacilityBilling === 'isFacilityBilling'
      ? yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD)
      : yup.string().nullable();
  }),
  billingEin: yup.string().when('isFacilityBilling', ([isFacilityBilling]) => {
    return isFacilityBilling === 'isFacilityBilling'
      ? yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD)
      : yup.string().nullable();
  }),
  billingNpi: yup.string().max(29, TOO_LONG).trim().required(REQUIRED_FIELD),
});
