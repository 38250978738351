import { FormikErrors, FormikProps } from 'formik';
import { ClearFiltersSVG, DeleteRedBtnSVG } from 'assets/icons';
import * as S from './styles';
import { AppointmentFormValues } from '../../AppointmentDrawer';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { constantTimePicker, visitLength } from 'constants/constants';
import SelectInput from 'components/atoms/SelectInput';
import { convertAMtoPM } from 'helpers/functions/convertAMtoPM';
import { AppointmentStatus } from 'types/StatusTypes';
import { AppointmentsNew, Visits } from 'types/AppointmentNewTypes';
import React, { useEffect, useMemo } from 'react';
import { useGetAllDoctorsQuery } from 'store/api/staff/staffApi';
import { useAppSelector } from 'hooks/useAppSelector';
import { Patients } from 'types/ClinicTypes';
import { Roles } from 'types/Roles';
import { AutoCompleteFromEnum } from 'components/atoms/AutoComplete/AutoCompleteFromEnum';

type Props = {
  visit: Partial<Visits>;
  formik: FormikProps<AppointmentFormValues>;
  selectedAppointment?: AppointmentsNew;
  selectedClinicId: string;
  serviceName: string;
  arrTime: string[] | null;
  index: number;
  recordTime?: {
    time?: string;
    date?: string;
  };
  defaultServiceTime?: number;
};

const VisitReasonCard = ({
  visit,
  formik,
  selectedAppointment,
  arrTime,
  index,
  recordTime,
  selectedClinicId,
  serviceName,
  defaultServiceTime,
}: Props) => {
  useEffect(() => {
    if (recordTime) {
      formik.setFieldValue(`visits.${index}.startTime`, recordTime.time?.slice(0, 5));
    } else if (arrTime && arrTime.length > 0 && !recordTime && !selectedAppointment) {
      formik.setFieldValue(`visits.${index}.startTime`, arrTime[0].slice(0, 5));
    }
  }, [recordTime, arrTime, selectedAppointment]);

  const getDoctors = useGetAllDoctorsQuery({
    id: selectedClinicId,
    'userStatus[]': 'active',
    take: 500,
  });

  const me = useAppSelector(state => state.auth);

  const isEditableAppointment = useMemo(() => {
    return selectedAppointment?.status
      ? selectedAppointment?.status === AppointmentStatus.PENDING
      : true;
  }, [selectedAppointment?.status]);

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          {serviceName}
          {isEditableAppointment && (
            <DeleteRedBtnSVG
              onClick={() => {
                if (
                  visit.typeServiceId &&
                  visit.typeServiceId === formik.values.mainCoveredService
                ) {
                  formik.setFieldValue('mainCoveredService', '');
                }
                const filteredReasons = formik.values.typeServiceIdes.filter(
                  item => item !== visit.typeServiceId,
                );
                const filteredVisits = formik.values.visits.filter(
                  item => item.typeServiceId !== visit.typeServiceId,
                );
                formik.setFieldValue('typeServiceIdes', filteredReasons);
                formik.setFieldValue('visits', filteredVisits);
              }}
            />
          )}
        </S.Title>
        <S.InputRow>
          <S.SelectInputWrapper>
            <SelectInput
              label="Preferred Time"
              isRequired
              disabled={!isEditableAppointment}
              id={`visits.${index}.startTime`}
              name={`visits.${index}.startTime`}
              value={visit.startTime}
              error={
                !!(formik.errors?.visits as FormikErrors<Visits[]>)?.[index]?.startTime
              }
              helperText={
                (formik.errors?.visits as FormikErrors<Visits[]>)?.[index]?.startTime
              }
              onChange={(e: SelectChangeEvent<unknown>) => {
                formik.setFieldError(`visits.${index}.startTime`, '');
                formik.handleChange(e);
              }}
            >
              {arrTime?.length && arrTime?.length > 0
                ? arrTime.map(time => (
                    <MenuItem key={time} value={time.slice(0, 5)}>
                      <S.MenuItemContent>{convertAMtoPM(time)}</S.MenuItemContent>
                    </MenuItem>
                  ))
                : constantTimePicker.map(time => (
                    <MenuItem key={time.value} value={time.value}>
                      <S.MenuItemContent>{time.name}</S.MenuItemContent>
                    </MenuItem>
                  ))}
            </SelectInput>
            {!formik.values.date && formik.values.clinicId && (
              <S.HelperText>To select a time, fill in the preferred day</S.HelperText>
            )}
          </S.SelectInputWrapper>
          <AutoCompleteFromEnum
            mainLabel={'Visit length'}
            data={
              defaultServiceTime
                ? [
                    defaultServiceTime,
                    ...visitLength.filter(i => i !== defaultServiceTime),
                  ]
                : visitLength
            }
            value={visit.visitLengthMinutes || 0}
            onChange={val =>
              formik.setFieldValue(`visits.${index}.visitLengthMinutes`, val)
            }
            suffix={'min'}
            isRequired
            disabled={!isEditableAppointment}
            error={
              !!(formik.errors?.visits as FormikErrors<Visits[]>)?.[index]
                ?.visitLengthMinutes
            }
            helperText={
              (formik.errors?.visits as FormikErrors<Visits[]>)?.[index]
                ?.visitLengthMinutes
            }
            defaultServiceTime={defaultServiceTime}
          />
        </S.InputRow>
        {me.role !== Roles.PATIENT &&
          visit.specialistId &&
          selectedAppointment?.status !== AppointmentStatus.CANCELED &&
          selectedAppointment?.status !== AppointmentStatus.COMPLETED && (
            <S.InputRow>
              <S.SVG
                onClick={() => formik.setFieldValue(`visits.${index}.specialistId`, '')}
              >
                <ClearFiltersSVG />
                Clear specialist
              </S.SVG>
            </S.InputRow>
          )}
        {me.role !== Roles.PATIENT && (
          <S.InputRow>
            <SelectInput
              label="Specialist name"
              id={`visits.${index}.specialistId`}
              name={`visits.${index}.specialistId`}
              value={visit.specialistId}
              onChange={(e: SelectChangeEvent<unknown>) => {
                formik.handleChange(e);
              }}
              disabled={
                selectedAppointment?.status === AppointmentStatus.COMPLETED ||
                selectedAppointment?.status === AppointmentStatus.CANCELED
              }
            >
              {getDoctors.data?.rows.map((item: Patients) => {
                return (
                  <MenuItem key={item.id} value={item.userId}>
                    <S.MenuItemContent>
                      {item.user?.profile?.firstName + ' ' + item.user?.profile?.lastName}
                    </S.MenuItemContent>
                  </MenuItem>
                );
              })}
            </SelectInput>
          </S.InputRow>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default VisitReasonCard;
