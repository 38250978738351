import DonutChart from 'components/atoms/DonutChart';
import * as S from '../../styles';

const InvoicesCount = () => {
  const labels = ['Paid Invoices', 'Unpaid Invoices'];

  const data = [90, 10];

  const options = {
    type: 'doughnut',
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data: any, i: number) => ({
              text: `${chart.data.labels[i]} (${data}%)`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i,
            }));
          },
        },
      },
    },
  };

  return (
    <S.MainInfoCard>
      <S.MainContent>
        <S.SubtitleText>Invoices</S.SubtitleText>
        <S.ChartWrapper>
          <S.Chart>
            <DonutChart
              title="Tickets"
              labels={labels}
              records={data}
              options={options}
            />
          </S.Chart>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInfoCard>
  );
};

export default InvoicesCount;
