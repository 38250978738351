import { ChatItem, SearchInput } from 'components';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';
import { useAppSelector } from 'hooks/useAppSelector';
import { getChats, joinChat, readMessage } from 'hooks/useChat';
import { useDebounce } from 'use-debounce';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setCurrentChat } from 'store/slices/chatSlice';
import { ChatItemType } from 'types/ChatTypes';
import MessagesBox from './Messages';

type Props = {
  socket?: Socket;
};

const Chats: React.FC<Props> = ({ socket }) => {
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const chats = useAppSelector(({ chats }) => chats.chats);
  const connected = useAppSelector(({ chats }) => chats.connected);
  const messageResponse = useAppSelector(({ chats }) => chats.messageResponse);
  const currentChat = useAppSelector(({ chats }) => chats.currentChat);

  const handleChatClick = (id: string) => {
    joinChat(id);
    dispatch(setCurrentChat(id));
  };

  useEffect(() => {
    if (messageResponse?.messages?.rows[0]?.id && currentChat) {
      readMessage(messageResponse.messages.rows[0].id, currentChat);
    }
  }, [messageResponse]);

  const handleSearch = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (connected) {
      getChats(1, debouncedSearchValue);
    }
  }, [debouncedSearchValue, connected]);

  return (
    <S.ChatsWrapper>
      <S.SubtitleBar>
        <S.SubtitleText>Chats</S.SubtitleText>
      </S.SubtitleBar>
      <S.Content>
        <S.ChatsItems>
          <S.SearchLine>
            <S.SearchWrapper>
              <SearchInput value={searchValue} onChange={handleSearch} />
            </S.SearchWrapper>
          </S.SearchLine>
          <S.ChatItemsWrapper>
            <S.ScrollContainer>
              {chats?.rows?.map((chat: ChatItemType) => {
                return (
                  <S.ChatWrapper
                    key={chat.chatId}
                    onClick={() => {
                      handleChatClick(chat.chatId);
                    }}
                  >
                    <ChatItem
                      clinicName={chat?.chat?.name}
                      src={chat.chat?.clinic?.logo?.url}
                      chat={chat}
                      $active={currentChat === chat.chatId}
                      msgCount={chat.unreadMessagesCount}
                    />
                  </S.ChatWrapper>
                );
              })}
            </S.ScrollContainer>
          </S.ChatItemsWrapper>
        </S.ChatsItems>
        {messageResponse && currentChat && (
          <MessagesBox searchValue={debouncedSearchValue} />
        )}
      </S.Content>
    </S.ChatsWrapper>
  );
};

export default Chats;
