import * as S from './styles';
import { AddHealthRecordSVG } from 'assets/icons';
import { useState } from 'react';
import { getStatusColor } from 'helpers/getStatusColor';
import { Event } from 'react-big-calendar';
import { EventView } from 'types/AppointmentsTypes';
import NotificationPopover from 'components/organismus/NotificationPopover';
import Fade from '@mui/material/Fade';
import { bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverInformation from './HoverInformation';
import { useAppSelector } from 'hooks/useAppSelector';
import ClickEventMenu from './ClickEventMenu';
import { Dialog } from '../..';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import { setMessage } from 'store/slices/message';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  useAppointmentDeleteMutation,
  useDeleteVisitServiceMutation,
} from 'store/api/appointment/appointmentApi';
import { DrawerMode } from 'types/DrawerMode';
import { showDrawer } from 'store/slices/drawerSlice';

type Props = {
  type: string;
  time: string;
  status: string;
  isFirstTimeVisit: boolean;
  fullEvent: Event;
};

const CalendarEvent: React.FC<Props> = ({
  type,
  time,
  status,
  isFirstTimeVisit,
  fullEvent,
}) => {
  const selectedClinic = useAppSelector(state => state.selectedClinic);
  const currentService = selectedClinic.services?.find(
    i => i.id === fullEvent?.resource?.visit?.typeServiceId,
  );

  const statusColor = getStatusColor(status).status;

  const [isHover, setIsHover] = useState(false);

  const currentEvent = fullEvent as EventView;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  const [anchorElReport, setAnchorElReport] = useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();

  const handleAnchorReport = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    anchorElReport ? setAnchorElReport(null) : setAnchorElReport(e.currentTarget);
  };

  const [deleteAppointment] = useAppointmentDeleteMutation({});

  const [deleteVisit] = useDeleteVisitServiceMutation({});

  const handleDelete = () => {
    fullEvent.resource?.selectedEvent?.visits.length > 1
      ? deleteVisit({ id: fullEvent.resource?.visit?.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Service was successfully deleted from appointment',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          })
      : deleteAppointment({ id: fullEvent.resource?.selectedEvent.id })
          .unwrap()
          .then(res => {
            dispatch(
              setMessage({
                message: 'Appointment was successfully deleted',
                type: 'success',
              }),
            );
          })
          .catch(error => {
            dispatch(
              setMessage({
                message: error.data.message,
                type: 'error',
              }),
            );
          });
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleChangeDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      <S.MainWrapper {...bindHover(popupState)}>
        <HoverPopover
          className="rr"
          TransitionProps={{ timeout: 200 }}
          TransitionComponent={Fade}
          transitionDuration={1500}
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: -10,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <HoverInformation event={currentEvent} />
        </HoverPopover>
        <S.EventWrapper
          background={
            fullEvent?.resource?.isFirstTimeVisit &&
            currentService?.isUseForFirstTimeVisit &&
            currentService?.firstVisitColor
              ? currentService?.firstVisitColor
              : (currentService?.color ?? 'transparent')
          }
          border={isHover ? '#0084B1' : '#848A9B'}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {isHover && (
            <>
              <S.ButtonAdd onClick={handleAnchorReport}>
                <AddHealthRecordSVG />
                <NotificationPopover
                  open={Boolean(anchorElReport)}
                  anchorEl={anchorElReport}
                  onClose={() => {
                    setAnchorElReport(null);
                  }}
                >
                  <ClickEventMenu
                    setOpenDialog={setOpenDialog}
                    visit={fullEvent.resource?.visit}
                    appointment={fullEvent.resource?.selectedEvent}
                  />
                </NotificationPopover>
              </S.ButtonAdd>
            </>
          )}
          <S.SmallContent>
            <Dialog
              open={openDialog}
              onClose={() => {
                dispatch(
                  showDrawer({
                    show: false,
                    mode: DrawerMode.DEFAULT,
                    props: null,
                  }),
                );
                handleChangeDialog();
              }}
            >
              <ConfirmDeleteDialog
                onClose={handleChangeDialog}
                fullTitle={
                  fullEvent.resource?.selectedEvent?.visits.length > 1
                    ? `Are you sure want to delete current service from appointment #${fullEvent.resource?.selectedEvent?.id.slice(
                        0,
                        8,
                      )}?`
                    : 'Are you sure you want to delete the entire appointment?'
                }
                helperText={
                  fullEvent.resource?.selectedEvent?.visits.length > 1
                    ? 'You will delete only one current service from appointment'
                    : 'You will delete the entire appointment and lose progress'
                }
                onSuccess={handleDelete}
              />
            </Dialog>
            <S.StatusBar className="small" status={statusColor} />
            <S.ContentWrap>
              <S.Type>{fullEvent?.resource?.type ?? '-'}</S.Type>
            </S.ContentWrap>
          </S.SmallContent>
        </S.EventWrapper>
      </S.MainWrapper>
    </>
  );
};

export default CalendarEvent;
