import { ChangeEvent } from 'react';
import * as S from './styles';
import { Button, DatePicker, Input, RadioButton, SelectInput } from 'components';
import { MenuItem, RadioGroup, SelectChangeEvent } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import { useAddPatientMutation } from 'store/api/clinics/clinicApi';
import { useDispatch } from 'react-redux';
import { showDrawer } from 'store/slices/drawerSlice';
import { setMessage } from 'store/slices/message';
import { DrawerMode } from 'types/DrawerMode';
import { AuthFieldType } from 'types/enums/AppointmentEnum';
import { StatesUSA } from 'constants/statesUSA';
import ReactInputMask from 'react-input-mask';
import { useAppSelector } from 'hooks/useAppSelector';
import { emailValidator, phoneValidator } from 'constants/constants';

type FormValues = {
  firstName: string;
  lastName: string;
  sex: string;
  birthDate: Dayjs;
  phone: string;
  email: string;
  emergencyContact: string;
  contactPhone: string;
  state: string;
  city: string;
  zipcode: string;
  street: string;
  insuranceName: string;
  relation: string;
  cellPhone: string;
  authFieldType: AuthFieldType;
};

const AddPatientShape = yup.object().shape({
  firstName: yup.string().required('Is required'),
  lastName: yup.string().required('Is required'),
  phone: phoneValidator,
  sex: yup.string().required('Is required'),
  birthDate: yup.date().required('Is required'),
  email: emailValidator,
  contactPhone: phoneValidator,
  emergencyContact: yup.string().required('Is required'),
  state: yup.string().required('Is required'),
  city: yup.string().required('Is required'),
  zipcode: yup.string().required('Is required'),
  street: yup.string().required('Is required'),
  // insuranceName: yup.string().required("Is required"),
  // relation: yup.string().required("Is required"),
  authFieldType: yup
    .mixed()
    .oneOf([AuthFieldType.EMAIL, AuthFieldType.PHONE])
    .required('Is required'),
});

type Props = {
  selectedClinic?: string;
};

const AddPatientDrawer: React.FC<Props> = ({ selectedClinic }) => {
  const dispatch = useDispatch();
  const [addPatient] = useAddPatientMutation({});

  const clinicId = useAppSelector(state => state.selectedClinic).id;

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      sex: '',
      birthDate: dayjs(),
      phone: '',
      email: '',
      emergencyContact: '',
      contactPhone: '',
      state: '',
      city: '',
      zipcode: '',
      street: '',
      insuranceName: '',
      relation: '',
      cellPhone: '',
      authFieldType: AuthFieldType.PHONE,
    },
    validateOnChange: false,
    validationSchema: AddPatientShape,
    onSubmit: async () => {
      const data = {
        id: clinicId,
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
          sex: values.sex,
          dateOfBirth: values.birthDate,
          cellPhone: values.phone,
          emergencyContact: values.emergencyContact,
          emergencyContactPhone: values.contactPhone,
          state: values.state,
          city: values.city,
          zipCode: values.zipcode,
          address: values.street,
          // insurance: {
          //   insuranceName: values.insuranceName,
          //   relation: values.relation,
          // },
        },
        user: {
          authField:
            values.authFieldType === AuthFieldType.EMAIL ? values.email : values.phone,
          authFieldType: values.authFieldType,
          phone: values.phone,
          ...(values.email && { email: values.email }),
        },
      };

      await addPatient(data)
        .unwrap()
        .then(res => {
          dispatch(
            showDrawer({
              show: false,
              mode: DrawerMode.DEFAULT,
              props: null,
            }),
          );
          resetForm();
          dispatch(setMessage({ message: res.message, type: 'success' }));
        })
        .catch(error => {
          dispatch(
            showDrawer({
              show: false,
              mode: DrawerMode.DEFAULT,
              props: null,
            }),
          );
          dispatch(setMessage({ message: error.data.message, type: 'error' }));
        });
    },
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.Article>Add Patient</S.Article>
          <S.Inputs>
            <S.InputRow>
              <Input
                id="firstName"
                name="firstName"
                label="First name"
                isRequired
                error={!!errors.firstName}
                helperText={errors.firstName}
                onChange={handleChangeInput}
                value={values.firstName}
              />
              <Input
                id="lastName"
                name="lastName"
                label="Last name"
                isRequired
                error={!!errors.lastName}
                helperText={errors.lastName}
                onChange={handleChangeInput}
                value={values.lastName}
              />
            </S.InputRow>
            <S.InputRow>
              <SelectInput
                label="Sex"
                id="sex"
                isRequired
                name="sex"
                error={!!errors.sex}
                helperText={errors.sex}
                value={values.sex}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('sex', '');
                  handleChange(e);
                }}
              >
                <MenuItem value={'male'}>
                  <S.MenuItemContent>{'Male'}</S.MenuItemContent>
                </MenuItem>
                <MenuItem value={'female'}>
                  <S.MenuItemContent>{'Female'}</S.MenuItemContent>
                </MenuItem>
              </SelectInput>
              <DatePicker
                label="Birth Date"
                isRequired
                disableFuture
                value={values.birthDate}
                onChange={value => setFieldValue('birthDate', value)}
                id="birthDate"
                name="birthDate"
                // error={!!values.prefferedDate}
                helperText={values.birthDate.day() === Number(isNaN) ? 'Is required' : ''}
              />
            </S.InputRow>
            <S.SubArticle>Contact information</S.SubArticle>
            <S.InputRow className="radio">
              <RadioGroup
                value={values.authFieldType}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('authFieldType', (event.target as HTMLInputElement).value)
                }
              >
                <S.RadioItem>
                  <S.Label>
                    <S.LabelArticle>Registration type</S.LabelArticle>
                    <S.HelperText>
                      Please specify the type of Registration of this employee (which will
                      be further used by him/her for authorization in the app)
                    </S.HelperText>
                  </S.Label>
                  <S.RadioButtons>
                    <RadioButton label="Phone" value={AuthFieldType.PHONE} />
                    <RadioButton label="Email" value={AuthFieldType.EMAIL} />
                  </S.RadioButtons>
                </S.RadioItem>
              </RadioGroup>
            </S.InputRow>
            <S.InputRow>
              <ReactInputMask
                mask="+19999999999"
                value={values.phone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  label="Phone "
                  id="phone"
                  isRequired
                  name="phone"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </ReactInputMask>

              <Input
                isRequired
                id="email"
                name="email"
                label="Email"
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleChangeInput}
                value={values.email}
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                isRequired
                id="emergencyContact"
                name="emergencyContact"
                label="Emergency Contact"
                error={!!errors.emergencyContact}
                helperText={errors.emergencyContact}
                onChange={handleChangeInput}
                value={values.emergencyContact}
              />
              <ReactInputMask
                mask="+19999999999"
                value={values.contactPhone}
                onChange={handleChangeInput}
                disabled={false}
              >
                <Input
                  isRequired
                  label="Contact Phone "
                  id="contactPhone"
                  name="contactPhone"
                  error={!!errors.contactPhone}
                  helperText={errors.contactPhone}
                />
              </ReactInputMask>
            </S.InputRow>
            <S.SubArticle>Address</S.SubArticle>
            <S.InputRow>
              <SelectInput
                label="State"
                isRequired
                id="state"
                name="state"
                value={values.state}
                error={!!errors.state}
                helperText={errors.state}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError('state', '');
                  handleChange(e);
                }}
              >
                {StatesUSA.map(state => (
                  <MenuItem key={state.name} value={state.abbreviation}>
                    <S.MenuItemContent>{state.name}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
              <Input
                id="city"
                name="city"
                label="City"
                error={!!errors.city}
                helperText={errors.city}
                onChange={handleChangeInput}
                value={values.city}
                isRequired
              />
            </S.InputRow>
            <S.InputRow>
              <Input
                id="zipcode"
                name="zipcode"
                label="Zipcode"
                error={!!errors.zipcode}
                helperText={errors.zipcode}
                onChange={handleChangeInput}
                value={values.zipcode}
                isRequired
              />
              <Input
                id="street"
                name="street"
                label="Street"
                error={!!errors.street}
                helperText={errors.street}
                onChange={handleChangeInput}
                value={values.street}
                isRequired
              />
            </S.InputRow>
            {/* <S.SubArticle>Insurance company</S.SubArticle>
            <S.InputRow>
              <Input
                id="insuranceName"
                name="insuranceName"
                label="Insurance Name"
                error={!!errors.insuranceName}
                helperText={errors.insuranceName}
                onChange={handleChangeInput}
                value={values.insuranceName}
                isRequired
              />
              <SelectInput
                label="Relationship of Patient"
                id="relation"
                isRequired
                name="relation"
                error={!!errors.relation}
                helperText={errors.relation}
                value={values.relation}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setFieldError("relation", "");
                  handleChange(e);
                }}
              >
                <MenuItem value={Relation.SELF}>
                  <S.MenuItemContent>Self</S.MenuItemContent>
                </MenuItem>
                <MenuItem value={Relation.CHILD}>
                  <S.MenuItemContent>Child</S.MenuItemContent>
                </MenuItem>
                <MenuItem value={Relation.SPOUSE}>
                  <S.MenuItemContent>Spouse</S.MenuItemContent>
                </MenuItem>
                <MenuItem value={Relation.OTHER}>
                  <S.MenuItemContent>Other</S.MenuItemContent>
                </MenuItem>
              </SelectInput>
            </S.InputRow> */}
          </S.Inputs>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text="Create" type="submit" />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default AddPatientDrawer;
