import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { useDashVisitReasonsGetQuery } from 'store/api/dashboard/dashboardApi';
import * as S from '../../styles';
import { CircularLoader, DashboardCard, VerticalChart } from 'components';
import { ucFirst } from 'helpers/functions/toUpperCase';

const DashVisitReasons = () => {
  const labels = ['Acupuncture', 'Massage', 'Herbs', 'Physical Therapy', 'Other'];

  const selectedClinic = useAppSelector(state => state.selectedClinic);

  const getStat = useDashVisitReasonsGetQuery({
    clinicId: selectedClinic.id,
    startDate: '2023-01-01',
    endDate: '2024-03-01',
  });

  const [allLabels, setAllLabels] = useState<Array<string>>([]);

  useEffect(() => {
    if (getStat.currentData?.graph) {
      setAllLabels([]);
      getStat.currentData?.graph?.map((item: any) => {
        setAllLabels(prev => [...prev, ucFirst(item.reason)]);
      });
    }
  }, [getStat]);

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Canceled',
        backgroundColor: '#E17777',
        data: getStat.currentData?.graph?.map((item: any) => item.canceledCount),
        borderRadius: 7,
      },
      {
        label: 'Completed',
        backgroundColor: '#0084B1',
        borderRadius: 10,
        data: getStat.currentData?.graph?.map((item: any) => item.completedCount),
      },
    ],
  };

  return (
    <S.MainInformation>
      <S.MainContent>
        <S.SubtitleText>Visit Reasons</S.SubtitleText>
        <S.ChartWrapper>
          <S.Chart>
            {getStat.isLoading || getStat.isFetching ? (
              <CircularLoader color="#0084B1" />
            ) : (
              <VerticalChart stackedData={data} propsOptions={options} />
            )}
          </S.Chart>
          <S.Items>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Total Completed"
                number={getStat.currentData?.totalCompleted}
                $isGray
              />
            </S.DashboardSmallWrapper>
            <S.DashboardSmallWrapper>
              <DashboardCard
                $isLoading={getStat.isFetching || getStat.isLoading}
                article="Total Canceled"
                number={getStat.currentData?.totalCanceled}
                $isGray
              />
            </S.DashboardSmallWrapper>
          </S.Items>
        </S.ChartWrapper>
      </S.MainContent>
    </S.MainInformation>
  );
};

export default DashVisitReasons;
