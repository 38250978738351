import * as S from './styles';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import StatisticHeaderBar from 'pages/Receptionist/components/Dashboard/components/StatisticHeaderBar';
import AveragePatientVisits from 'pages/Receptionist/components/Dashboard/components/AveragePatientVisits';
import TicketsCount from 'pages/Receptionist/components/Dashboard/components/TicketsCount';
import Masseur from 'pages/Receptionist/components/Dashboard/components/Masseur';
import DashVisitReasons from 'pages/Receptionist/components/Dashboard/components/DashVisitReasons';
import { Roles } from 'types/Roles';
import DashCash from 'pages/Receptionist/components/Dashboard/components/DashCash';
import InvoicesCount from 'pages/Receptionist/components/Dashboard/components/InvoicesCount';

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(state => state.auth);

  return (
    <S.DashboardWrapper>
      <S.SubtitleBar>
        <S.Article>Dashboard</S.Article>
        <S.SubtitleItems></S.SubtitleItems>
      </S.SubtitleBar>
      <S.SubHeader>
        <S.SelectWrapper></S.SelectWrapper>
      </S.SubHeader>
      <S.ScrollWrapper>
        <S.Content>
          <StatisticHeaderBar />
          <AveragePatientVisits />
          <S.RowCards>
            <InvoicesCount />
            <TicketsCount />
            <Masseur />
          </S.RowCards>
          <DashVisitReasons />
          {userInfo.role !== Roles.SUPER_ADMIN && <DashCash />}
        </S.Content>
      </S.ScrollWrapper>
    </S.DashboardWrapper>
  );
};

export default Dashboard;
