import { FormikProps } from 'formik';
import {
  handleChangeDatePicker,
  handleChangeInputRef,
} from 'helpers/FormikFuncs/formikFuncs';
import { DatePicker, Input } from 'components';
import { ChangeEvent, forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import FirstName from 'components/organismus/AppointmentDrawer/components/PatientInformation/FirstName';
import { useAppSelector } from 'hooks/useAppSelector';
import LastName from 'components/organismus/AppointmentDrawer/components/PatientInformation/LastName';
import { ClearFiltersSVG } from 'assets/icons';
import DOB from 'components/organismus/AppointmentDrawer/components/PatientInformation/DOB';
import { AppointmentsNew, Step } from 'types/AppointmentNewTypes';
import * as S from '../../styles';
import { PagePTInitialType } from '../../../pages/PagePTInitialConsultation/PagePTInitialType';

type Props = {
  formik: FormikProps<PagePTInitialType>;
  stepperValues?: Step;
  appointmentInformation?: AppointmentsNew;

  setIsDirty?: () => void;
};
const PTUserBlock = forwardRef<Record<string, unknown>, Props>(
  ({ formik, stepperValues, appointmentInformation, setIsDirty }, ref) => {
    const [selectedValue, setSelectedValue] = useState<any>('');
    const { id } = useAppSelector(state => state.selectedClinic);
    const [emptyDatePicker, setEmptyDatePicker] = useState(false);

    useEffect(() => {
      if (appointmentInformation) {
        setSelectedValue(appointmentInformation.patient);
      }
    }, [appointmentInformation]);

    useEffect(() => {
      if (selectedValue) {
        formik.setErrors({
          patientInfo: {
            patientName: '',
          },
        });
        formik.setValues({
          ...formik.values,
          userId: selectedValue?.id,
          patientInfo: {
            birthDate: selectedValue.profile?.dateOfBirth,
            patientName:
              selectedValue.profile?.firstName + ' ' + selectedValue.profile?.lastName,
          },
        });
      } else
        formik.setValues({
          ...formik.values,
          userId: '',
          patientInfo: {
            cpt: '',
            diagnostics: '',
            birthDate: '',
            date: '',
            patientName: '',
          },
        });
    }, [selectedValue]);

    return (
      <>
        <S.Article>Patient information</S.Article>
        <S.Line>
          <S.InputWrapper className="px240">
            <Input
              label="Diagnosis/ICD-10:"
              defaultValue={formik.values.patientInfo.diagnostics}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'patientInfo.diagnostics', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <S.InputWrapper>
              <DatePicker
                label="Date"
                defaultValue={dayjs(formik.values?.patientInfo.date)}
                onChange={value => {
                  handleChangeDatePicker(value, 'patientInfo.date', ref, setIsDirty);
                }}
              />
            </S.InputWrapper>
          </S.InputWrapper>
        </S.Line>
        {selectedValue && !appointmentInformation && (
          <S.ResetButton
            onClick={() => {
              setEmptyDatePicker(true);
              setSelectedValue('');
            }}
          >
            <ClearFiltersSVG />
            Change user
          </S.ResetButton>
        )}

        {formik.errors.patientInfo?.patientName && (
          <S.Line>
            <S.ErrorText>{'USER INFORMATION IS REQUIRED!'}</S.ErrorText>
          </S.Line>
        )}

        <S.Line>
          <S.InputWrapper className="px240">
            <FirstName
              error={formik.errors.patientInfo?.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <LastName
              error={formik.errors.patientInfo?.patientName}
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
            />
          </S.InputWrapper>
          <S.InputWrapper className="px240">
            <DOB
              ref={ref}
              byClick
              formik={formik}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              clinic={id}
              appointmentInformation={appointmentInformation}
              emptyDatePicker={emptyDatePicker}
              setEmptyDatePicker={setEmptyDatePicker}
            />
          </S.InputWrapper>
        </S.Line>
        <S.Line>
          <S.InputWrapper className="px240">
            <Input
              label="CPT"
              defaultValue={formik.values.patientInfo.cpt}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleChangeInputRef(e, 'patientInfo.cpt', ref, setIsDirty);
              }}
            />
          </S.InputWrapper>
        </S.Line>
      </>
    );
  },
);

export default PTUserBlock;
