import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Button from 'components/atoms/Button';
import * as S from './styles';
import AutoCompleteInput from 'components/atoms/AutoComplete';
import Input from 'components/atoms/Input';
import SelectInput from 'components/atoms/SelectInput';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useAppSelector } from 'hooks/useAppSelector';
import { Roles } from 'types/Roles';
import { usePatientsNoCacheGetQuery } from 'store/api/clinics/clinicApi';
import { useDebounce } from 'use-debounce';
import { Patients } from 'types/ClinicTypes';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from 'components/atoms/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTicketsCreateMutation } from 'store/api/tickets/ticketApi';
import { useDispatch } from 'react-redux';
import { showDrawer } from 'store/slices/drawerSlice';
import { DrawerMode } from 'types/DrawerMode';
import { setMessage } from 'store/slices/message';
import { useAppointmentGetForTicketQuery } from 'store/api/appointment/appointmentApi';
import StatusPin from 'components/atoms/StatusPin';
import { TicketType } from 'types/TicketType';
import { AppointmentsNew, Visits } from 'types/AppointmentNewTypes';
import SelectedCard from '../SelectedCard';
import useInfinityScroll from 'hooks/useInfinityScroll';

type Props = {
  ticket: TicketType;
};

type FormValues = {
  isBilling: boolean;
  taskName: string;
  description: string;
  prefferedDate: Dayjs;
  prefferedTime: string;
};

const TicketCreate: React.FC<Props> = ({ ticket }) => {
  const [assignTo, setAssignTo] = useState<Roles | string>('');

  const { role, id } = useAppSelector(state => state.auth);
  const selClinic = useAppSelector(state => state.selectedClinic);
  const [userPage, setUserPage] = useState(1);
  const [appointmentPage, setAppointmentPage] = useState(1);

  const [searchClientsValue, setSearchClientsValue] = useState('');
  const [debouncedSearchClients] = useDebounce(searchClientsValue, 500);
  const [selectedClientValue, setSelectedClientValue] = useState<any>(null);
  const [serverClientValue, setServerClientValue] = useState<Array<Patients>>([]);

  const [searchAppointmentsValue, setSearchAppointmentsValue] = useState('');
  const [debouncedSearchAppointments] = useDebounce(searchAppointmentsValue, 500);
  const [selectedAppointmentValue, setSelectedAppointmentValue] = useState<any>(null);
  const [serverAppointmentValue, setServerAppointmentValue] = useState<
    Array<AppointmentsNew>
  >([]);

  // const [getAll, status] = useAllPatientsGetMutation({});
  const rowData = usePatientsNoCacheGetQuery({
    q: debouncedSearchClients || undefined,
    id: selClinic.id,
    page: userPage,
    take: 20,
  });

  const appointmentsData = useAppointmentGetForTicketQuery({
    q: debouncedSearchAppointments || undefined,
    // visitDateTimeStart: getDay(selectedDate[0])[0].format("YYYY-MM-DD"),
    // visitDateTimeEnd: getDay(selectedDate[1])[1].format("YYYY-MM-DD"),
    take: 20,
    page: appointmentPage,
    clinicId: selClinic.id,
  });
  const { setLastElement: setUserLastElement } = useInfinityScroll({
    fetchNextData: () => setUserPage(userPage + 1),
    hasMore: userPage < (rowData.currentData?.lastPage ?? 0),
  });

  const { setLastElement: setAppointmentLastElement } = useInfinityScroll({
    fetchNextData: () => setAppointmentPage(appointmentPage + 1),
    hasMore: appointmentPage < (appointmentsData.currentData?.lastPage ?? 0),
  });

  const Schema = yup.object().shape({
    taskName: yup.string().required('Is required'),
    prefferedDate: yup.date().required('Is required'),
    description: yup.string().required('Is required'),
  });
  const [createTicket] = useTicketsCreateMutation({});
  const dispatch = useDispatch();

  const disableButton = useMemo(() => !assignTo, [assignTo]);

  const {
    values,
    resetForm,
    handleChange,
    handleSubmit,
    errors,
    setFieldValue,
    setValues,
    setFieldError,
  } = useFormik<FormValues>({
    initialValues: {
      isBilling: false,
      taskName: '',
      description: '',
      prefferedTime: '',
      prefferedDate: dayjs(),
    },
    validateOnChange: false,
    validationSchema: Schema,
    onSubmit: async () => {
      const data = {
        clinics: [selClinic.id],
        name: values.taskName,
        date: values.prefferedDate,
        description: values.description,
        time: values.prefferedTime,
        role: assignTo,
        ...(serverClientValue.length > 0 && {
          users: serverClientValue.map(item => item.userId),
        }),
        ...(serverAppointmentValue.length > 0 && {
          appointmentId: serverAppointmentValue.map(item => item.id)[0],
        }),
      };
      createTicket({ ...data })
        .unwrap()
        .then(res => {
          dispatch(
            setMessage({
              message: 'Ticket was successfully created',
              type: 'success',
            }),
          );
        })
        .catch(err => {
          dispatch(
            setMessage({
              message: err.data.message,
              type: 'error',
            }),
          );
        });
      dispatch(
        showDrawer({
          show: false,
          mode: DrawerMode.DEFAULT,
          props: null,
        }),
      );
    },
  });

  useEffect(() => {
    if (selectedClientValue) {
      setServerClientValue(prev => [...prev, selectedClientValue]);
      setSelectedClientValue(null);
    }
  }, [selectedClientValue]);

  useEffect(() => {
    if (selectedAppointmentValue) {
      setServerAppointmentValue(prev => [selectedAppointmentValue]);
      !serverClientValue?.some(
        otherItem =>
          (selectedAppointmentValue as AppointmentsNew).patientId === otherItem.userId,
      ) &&
        setServerClientValue(prev => [
          ...prev,
          {
            id: selectedAppointmentValue.patient?.id,
            userId: selectedAppointmentValue.patient?.id,
            clinicId: selectedAppointmentValue.clinicId,
            position: '',
            speciality: null,
            isPatient: true,
            createdAt: selectedAppointmentValue.createdAt,
            updatedAt: selectedAppointmentValue.updatedAt,
            user: selectedAppointmentValue.patient,
          },
        ]);

      setSelectedAppointmentValue(null);
    }
  }, [selectedAppointmentValue]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldError(e.target.id, '');
    handleChange(e);
  };

  interface MenuItemProps {
    value: Roles;
    label: string;
  }

  const menuItems: MenuItemProps[] = [
    { value: Roles.RECEPTIONIST, label: 'Receptionists' },
    { value: Roles.BILLING, label: 'Billing specialists' },
    { value: Roles.DOCTOR, label: 'Doctors' },
    { value: Roles.SUPER_ADMIN, label: 'Super admin' },
  ];

  const filteredMenuItems = menuItems.filter(menuItem => {
    switch (role) {
      case Roles.DOCTOR:
        return menuItem.value !== Roles.DOCTOR;
      case Roles.LOCATION_MANAGER:
        return (
          menuItem.value !== Roles.LOCATION_MANAGER && menuItem.value !== Roles.DOCTOR
        );
      case Roles.RECEPTIONIST:
        return menuItem.value !== Roles.RECEPTIONIST;
      case Roles.BILLING:
        return menuItem.value !== Roles.BILLING;
      case Roles.SUPER_ADMIN:
        return menuItem.value !== Roles.LOCATION_MANAGER;
      default:
        return true;
    }
  });

  return (
    <form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <S.Wrapper>
        <S.Content>
          <S.Article>{ticket ? 'Edit Ticket' : 'Make a Ticket'}</S.Article>
          <S.Inputs>
            <S.InputRow>
              <SelectInput
                label={'Assign to'}
                value={assignTo}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setAssignTo(e.target.value as string);
                }}
              >
                {filteredMenuItems.map(menuItem => (
                  <MenuItem key={menuItem.value} value={menuItem.value}>
                    <S.MenuItemContent>{menuItem.label}</S.MenuItemContent>
                  </MenuItem>
                ))}
              </SelectInput>
            </S.InputRow>
            {assignTo && (
              <>
                <S.InputRow>
                  <Input
                    label={'Task name'}
                    value={values.taskName}
                    onChange={handleChangeInput}
                    id="taskName"
                    name="taskName"
                    isRequired
                    error={!!errors.taskName}
                    helperText={errors.taskName}
                  />
                </S.InputRow>
                <S.InputRow>
                  <S.InputWrapper>
                    <AutoCompleteInput
                      data={rowData?.currentData?.rows || []}
                      mainLabel={'Clients'}
                      loading={!!(rowData.isFetching || rowData.isLoading)}
                      getOptionDisabled={option => {
                        return !!serverClientValue?.some(
                          otherItem => otherItem?.userId === option?.userId,
                        );
                      }}
                      searchValue={searchClientsValue}
                      setSearchValue={setSearchClientsValue}
                      selectedValue={selectedClientValue}
                      isShowBirthday
                      setSelectedValue={setSelectedClientValue}
                      setUserLastElement={setUserLastElement}
                    />
                    <S.SelectedCardsWrapper>
                      {serverClientValue &&
                        serverClientValue.map(item => {
                          return (
                            <SelectedCard
                              isCantUnpin={serverAppointmentValue?.some(
                                otherItem => otherItem?.patientId === item?.userId,
                              )}
                              onDelete={() =>
                                setServerClientValue(prevValue =>
                                  prevValue.filter(prevVal => prevVal.id !== item.id),
                                )
                              }
                              key={item.id}
                              text={
                                item.user?.profile?.firstName +
                                ' ' +
                                item.user?.profile?.lastName
                              }
                            />
                          );
                        })}
                    </S.SelectedCardsWrapper>
                  </S.InputWrapper>
                </S.InputRow>
                <S.InputRow>
                  <S.InputWrapper>
                    <AutoCompleteInput
                      data={appointmentsData.data?.rows || []}
                      customRender={true}
                      mainLabel={'Appointments'}
                      loading={!!appointmentsData.isLoading}
                      searchValue={searchAppointmentsValue}
                      getOptionDisabled={option => {
                        return !!serverAppointmentValue?.some(
                          otherItem => otherItem?.id === option?.id,
                        );
                      }}
                      options={appointmentsData.data?.rows || []}
                      setSearchValue={(val: string) => {
                        setAppointmentPage(1);
                        setSearchAppointmentsValue(val);
                      }}
                      selectedValue={selectedAppointmentValue}
                      setSelectedValue={setSelectedAppointmentValue}
                      getOptionLabel={(option: any) => option && option?.id}
                      renderOption={(props: any, option: any) => {
                        const refLast =
                          appointmentsData.data?.rows?.length ===
                          props['data-option-index'] + 1
                            ? setAppointmentLastElement
                            : null;

                        return (
                          <S.OptionWrap {...props} ref={refLast}>
                            {appointmentsData.isLoading ? (
                              'Searching...'
                            ) : (
                              <S.LiWrapper>
                                <S.UserInfo>
                                  #{option.id.slice(0, 8)}
                                  <S.Name>
                                    {option.patient?.profile?.firstName +
                                      ' ' +
                                      option.patient?.profile?.lastName}
                                  </S.Name>
                                </S.UserInfo>
                                <S.Visits>
                                  {option.visits?.map((item: Visits) => {
                                    const currentService = selClinic.services?.find(
                                      i => i.id === item.typeServiceId,
                                    );
                                    return (
                                      <StatusPin
                                        isFirstTimeVisit={option.isFirstTimeVisit}
                                        service={currentService}
                                      />
                                    );
                                  })}
                                </S.Visits>
                              </S.LiWrapper>
                            )}
                          </S.OptionWrap>
                        );
                      }}
                    />
                    <S.SelectedCardsWrapper>
                      {serverAppointmentValue &&
                        serverAppointmentValue.map(item => {
                          return (
                            <SelectedCard
                              onDelete={() =>
                                setServerAppointmentValue(prevValue =>
                                  prevValue.filter(prevVal => prevVal.id !== item.id),
                                )
                              }
                              key={item.id}
                              text={'#' + item.id.slice(0, 8)}
                            />
                          );
                        })}
                    </S.SelectedCardsWrapper>
                  </S.InputWrapper>
                </S.InputRow>
                <S.InputRow>
                  <Input
                    label={'Description'}
                    multiline
                    rows={5}
                    value={values.description}
                    onChange={handleChangeInput}
                    id="description"
                    name="description"
                    isRequired
                    error={!!errors.description}
                    helperText={errors.description}
                  />
                </S.InputRow>
                <S.InputRow>
                  <DatePicker
                    label="Preferred Date"
                    isRequired
                    disablePast
                    value={values.prefferedDate}
                    onChange={value => {
                      setFieldError('prefferedDate', '');
                      setFieldValue('prefferedDate', value);
                    }}
                    id="prefferedDate"
                    name="prefferedDate"
                    error={
                      !!errors.prefferedDate ||
                      !!(values.prefferedDate && !dayjs(values.prefferedDate).isValid())
                    }
                    helperText={
                      errors.prefferedDate || !dayjs(values.prefferedDate).isValid()
                        ? 'Not valid format'
                        : ''
                    }
                  />
                </S.InputRow>
              </>
            )}
          </S.Inputs>
        </S.Content>
        <S.Footer>
          <S.BtnWrapper>
            <Button text="Create" type="submit" disabled={disableButton} />
          </S.BtnWrapper>
        </S.Footer>
      </S.Wrapper>
    </form>
  );
};

export default TicketCreate;
